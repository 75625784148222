import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import logo from '../tmpImages/vv_logo.png'
// import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';

export function VVNavbar({colorClassName}) {
    return <div className={"main-header " + colorClassName}>
        <Navbar collapseOnSelect prop="defaultExpanded" expand="lg">
                <Navbar.Brand href="/" className="logo-text">
                    <img
                        alt=""
                        src={logo}
                        height="50"
                        className="d-inline-block align-center"
                    />{' '}
                    Virtuous Ventures
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="justify-content-end" style={{ width: "100%" }}>
                    <Nav.Link href="/blog">Blog</Nav.Link>
                    {/* NEW BADGE */}
                    {/* <Badge variant="secondary" className="new-button">New</Badge> */}
                    <Nav.Link href="/about">About</Nav.Link>
                    <Button variant="outline-secondary" className="nav-sign-up-button sign-up-button category-button align-center" href="/">Join Our Newsletter</Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </div>
}
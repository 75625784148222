import React from "react";
import Image from 'react-bootstrap/Image';
import { Helmet } from "react-helmet";
import green_banner from '../../tmpImages/7.png';
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function CompanySearchAnnounce() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Company Search</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Virtuous Ventures Launching Company Search Feature</h2>
                        <h4 className="blogpost-subheader">Find companies that align with your values.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Apr 13, 2021 · 4 min read</p>
                            </div>
                        </div>
                    </div>
                    <Image variant="top" src={ green_banner } className="blogpost-header-img"/>
                    <div className="interview-question">
                        <p><em>Virtuous Ventures</em> showcases early stage companies that develop products for positive impact. It explores how they leverage technology for good and the unique challenges at the intersection of social, environmental, and economic developments. In addition to its Founder Series, interviewing founders of mission driven companies, it is now launching a beta feature to provide visitors with a curated compilation of mission-driven startups that align with their values. This launch includes a subset of our database of 400+ inspiring companies. We are excited for you to <a className="blog-link" href="/company-search">check it out</a> and provide feedback!</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>What does it mean to be a mission-driven company?</b></h3>
                        <p>Every company has a mission statement, so what do we really mean at Virtuous Ventures when we say these companies are “mission-driven”?</p>
                        <Image src = "https://storage.googleapis.com/vv-images/companysearch_image1.png" className="blogpost-img"/>
                        <p className="img-caption">Mission statements of major corporations (source: https://mission-statement.com/)</p>
                        <p>Company mission statements are intended to be lofty and express the company’s values. <em>Amazon</em> values delightful consumer experiences and <em>Instagram</em> values connecting people through photographed moments. <em>Virtuous Ventures</em> showcases companies whose values and motivations embody an equitable, healthy and sustainable future; companies that not only embrace people and planet, but are driven by those values in parallel to being a profitable organization. They view success not solely in the return to investment, but in the positive impact that is core to their identity.</p>
                        <Image src = "https://storage.googleapis.com/vv-images/companysearch_image2.png" className="blogpost-img"/>
                        <p className="img-caption">Mission statements emphasizing values on people and planet. (sources: https://mission-statement.com/, https://ecosia.dropmark.com/374680/12189055)</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>The Curation Process</b></h3>
                        <p>Over the past year, every time we saw an amazing company that seemed to embrace a truly people and planet mission, we appended it to a list and classified it across several subcategories. Companies were reviewed on their mission statements and “About Us” content for indications that these values were considered core to their operations and success. All said, this is a subjective review, and we hope those using the feature will help keep us accountable to high standards. We aggregated these subcategories into six top-level categories.</p>
                        <Image src = "https://storage.googleapis.com/vv-images/companysearch_image3.png" className="blogpost-img"/>
                        <p className="img-caption">(Icon Source: Flaticon)</p>
                        <p><b>Education</b></p>
                        <p>Companies focusing on improving learning tools, student support, access to education through financial resources, and democratizing resources with technology.</p>
                        <p><b>Environment</b></p>
                        <p>Companies striving to improve the condition of our planet through means like waste reduction, renewable energy infrastructure, sustainable material innovation, ethical consumer options, carbon drawdown, conservation, restoration, and more.</p>
                        <p><b>Social Justice</b></p>
                        <p>Companies aiming to create equitable opportunity and access to human needs. This includes companies operating in spaces of diversity and inclusion, equal pay, mass incarceration, food/water/job security, elder care, disability support, and more.</p>
                        <p><b>Women</b></p>
                        <p>With 50% of the world’s population identifying as part of this category, we found a large number of companies focusing on the unique needs of women’s health, safety, career advancement, and more; thus justifying its own category.</p>
                        <p><b>Health and Wellness</b></p>
                        <p>Companies driving change in health and wellness by aiding access and destigmatizing mental and sexual wellness through novel approaches.</p>
                        <p><b>NGO and Public Sector Support</b></p>
                        <p>Change requires the policy and hands-on work performed by nonprofit and public sector organizations. However, it’s often hard to innovate and advance technologically with limited funding. Companies in this category leverage a for-profit model to provide other organizations the support they need.</p>
                        <p className="breakpoint-dots">• • •</p>
                        <p>We expect these categories to evolve to fit our user needs and culture. We hope our visitors can help shape the overall curation and categorization process, keeping us accountable to ensuring these companies live up to their claimed values.</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>How do I use the feature?</b></h3>
                        <p>You can access the feature <a className="blog-link" href="/company-search">here</a>. Right now the feature includes a subset of our database’s “Environment” category that is filterable by several subcategories.</p>
                        <Image src = "https://storage.googleapis.com/vv-images/companysearch_image4.png" className="blogpost-img"/>
                        <p>Each company card has a blurb about what it does, a link to its website, category tags, and social links to relevant platforms to obtain more company information like LinkedIn, AngelList, and Crunchbase.</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>What do I use this for?</b></h3>
                        <p>Honestly, you tell us! We felt we were sitting on a secret goldmine keeping this database of inspiring companies to ourselves, and felt it could be useful to job seekers, founders, investors, alike. We are building this for you, so please provide feedback <a className="blog-link" href="https://forms.gle/GLpTBoaEks9EntVG8">here</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

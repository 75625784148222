import React from "react";
import Image from 'react-bootstrap/Image';
import mental_health_market_report from '../../tmpImages/mental_health_trend_header.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function MentalHealthTrends() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Mental Health Trends</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Startup Market Trends in Mental Health</h2>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Jul 19, 2021 ·</p>
                            </div>
                        </div>
                    </div>
                    <Image variant="top" src={ mental_health_market_report } className="blogpost-header-img"/>

                    <p><em>In <a className="blog-link" href="/company-search?category=Health%20%26%20Wellness&subcategory=Mental%20Health">expanding our database</a> of mission driven companies to include those focused on mental health, we pulled together some of our favorites and identified key market trends. Check out our top 10 startups <a className="blog-link" href="/blog/top-10-mental-health">here</a>.</em></p>
                    <div className="interview-question">
                        <p>Serious Mental Illnesses (SRIs) impact 11.4 million people in the US [1]. More broadly, mental health issues affect roughly 50% of the population [2]. Despite mental health's widespread impact, many barriers prevent people from accessing the quality help they need; including cost, convenience, complexity, and fear of judgement. As people more openly discuss mental health and it progressively becomes destigmatized, individuals are increasingly seeking solutions to meet their needs. In the last few years, startups focusing on everything from self-service care to therapy-patient mapping to prescription management have sprung up with innovative approaches to addressing patient challenges such as navigating the insurance space and being triaged to the right level and specialty of care.</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>Industry Trends</b></h3>
                        <p>In 2019, Psychology/Counselors industry sales were $14.3 billion [3] with the mental health market valued at $38 billion in 2020 [4]. Recent events such as the Covid-19 pandemic and social injustices have increased the demand for mental health support, while there exists a shortage of therapists. The shortage is impacted by many therapists not accepting insurance due to insurer loopholes for payments, leaving therapists unable to provide patients the care they need [5].</p>
                        <p>Reviewing the companies in our database, we picked up on several trends in the space:</p>
                        <ol>
                            <li>Teletherapy</li>
                            <li>Preventative care</li>
                            <li>Group sessions</li>
                            <li>Positioning as complementary service to traditional healthcare</li>
                            <li>Coupling prescription delivery</li>
                        </ol>
                    </div>
                    <div className="interview-question">
                        <h3><b>Teletherapy</b></h3>
                        <p>A relevant growing trend is Telehealth, providing greater geographical access to professionals and convenience to healthcare providers and patients. The general telehealth market size is predicted to be $61.4B and projected to be $500B by 2027 [6]. Several mental health companies leverage a teletherapy model in providing digital access to licensed therapists such as <ArticleLink link="https://www.talkspace.com/" text="Talkspace"/>, <ArticleLink link="https://www.join-real.com/" text="Real"/>, <ArticleLink link="https://mindstrong.com/" text="MindStrong"/>, and more.</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>Preventative care</b></h3>
                        <p>Other observed trends were an emphasis toward preventative in addition to responsive care. Many companies provide a more self-service approach for preventative care with guided mindfulness techniques and education on cognitive behavioral therapy techniques. Some examples include <ArticleLink link="https://www.theshineapp.com/" text="Shine"/>, <ArticleLink link="https://www.calm.com/" text="Calm"/>, and <ArticleLink link="https://www.headspace.com/" text="Headspace"/>. Startups like <ArticleLink link="https://www.joinmodernhealth.com/" text="Modern Health"/> and Real combined various care offerings to different levels of care, providing more holistic solutions to meet each member's unique needs.</p>
                        <Image src = "https://storage.googleapis.com/vv-images/mental_health_trend_image_1.png" className="blogpost-img"/>
                        <p className="img-caption">Spectrum of self service apps to solutions offering teletherapy via train facilitators, listeners, counselors, coaches and licensed therapists.</p>   
                    </div>
                    <div className="interview-question">
                        <h3><b>Group sessions</b></h3>
                        <p>Several companies have also begun experimenting with untraditional group therapies which can often offer both a sense of community as well as more cost effective solutions for patients. In <ArticleLink link="/blog/modernhealth" text="a recent interview with Allison Watson"/>, CEO Modern Health, she expressed "a few years ago [group session] would have been viewed as a voodoo way to sell mental health, but it is working." Pace's entire product is coordinating facilitated group therapy sessions. <ArticleLink link="https://journey.live/" text="Journey"/>, <ArticleLink link="https://www.7cups.com/" text="7Cups"/>, and <ArticleLink link="https://www.betterhelp.com/" text="Betterhelp"/> have also started offering group lessons with guided discussion.</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>Positioning as complementary service to traditional healthcare</b></h3>
                        <p>Perhaps the industry's biggest problem is offering cost-effective solutions while growing as a profitable company. For the most part, these companies are positioning themselves as complimentary services to the traditional healthcare delivery paths, which have failed to serve the needs of many mental health patients. As an exception, <ArticleLink link="https://www.pathccm.com/" text="Path"/> claims their "customers are health plans and employers," listing partnerships with prominent insurance providers Anthem, UnitedHealthcare, and Oscar.</p>
                        <p>Other companies have either decided to market direct-to-consumers (DTC) or business-to-business (B2B) as an employee benefit. The latter approach provides support to patients at no out-of-pocket costs. These companies demonstrate the value proposition to businesses as a return on investment: employee mental wellbeing leads to increased productivity and reduced turnover. Journey's messaging exemplifies this, promising "the most cost-effective way to reduce turnover, tackle burnout, and help employees live happier, healthier lives." On the other end of the spectrum, DTC products and services are trying to monetize off providing more cost effective solutions such as self-serve content, group sessions, and trained listeners/facilitators. DTC solutions working with licensed therapists often still require funding routes through consumer insurance providers.</p>
                        <Image src = "https://storage.googleapis.com/vv-images/mental_health_trend_image_2.png" className="blogpost-img"/>
                        <p className="img-caption">Market landscape for mental health startups. Most are working outside the traditional healthcare delivery paths. *Calm has also partnered as an offering with <ArticleLink link="https://about.kaiserpermanente.org/our-story/news/announcements/calm-app-available-to-kaiser-permanente-members-at-no-cost" text="Kaiser Permanente"/>, a healthcare and insurance provider. Headspace has also partnered with <ArticleLink link="https://www.headspace.com/netflix" text="Netflix"/> to provide an alternative DTC avenue through interactive video content.</p>   
                    </div>
                    <div className="interview-question">
                        <h3><b>Coupling prescription delivery</b></h3>
                        <p>An alternative monetization strategy has been branching into prescription medication. Lemonade and Cerebral are both DTC services that merge teletherapy with medication procurement. This follows in the wake of telehealth meets medication for primary care like <ArticleLink link="https://ro.co/" text="Ro"/>, which has raised over $800M in funding, projecting to be a lucrative space [7]. Though the global market for prescription delivery is only projected to be $434M by the end of 2030 [8], companies can be competitive against big players like Walgreens and Express Scripts by offering end-to-end healthcare and establishing customer brand loyalty.</p>
                    </div>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p>Check out more startups progressing mental healthcare in our <a className="blog-link" href="/company-search?category=Health%20%26%20Wellness&subcategory=Mental%20Health">company database</a> and sign up for our <a className="blog-link" href="/">newsletter</a>.</p>
                    </div>
                    <div className="interview-question">
                        <p className="article-src-text">[1] https://mindstrong.com/ [2] https://getcerebral.com/ [3] https://www.kentleyinsights.com/Psychologists-and-Counselors-industry-market-research-report/ [4] https://www.grandviewresearch.com/industry-analysis/central-nervous-system-cns-therapeutic-market [5] https://www.healthline.com/health-news/shortage-of-therapists-disrupting-mental-health-care-111215#More-Barriers-Between-Professionals-and-Patients- [6] https://www.fortunebusinessinsights.com/industry-reports/telehealth-market-101065 [7] https://www.crunchbase.com/organization/roman-2 [8] https://www.futuremarketinsights.com/reports/prescription-delivery-services-market</p>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export function ArticleLink({link, text}) {
    return (
        <a className="blog-link" href={link} rel="noopener noreferrer">{text}</a>
        );
}
import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import corsali from '../../tmpImages/corsali.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function Corsali() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Corsali</title>
            </Helmet>
            <meta property="og:image" content={ corsali } />
            <meta property="og:image:width" content="180" />
            <meta property="og:image:height" content="110" />
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Corsali</h2>
                        <h4 className="blogpost-subheader">Creating economic opportunity through labeling data anywhere on low-bandwidth mobile devices.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">June 22, 2021 · 9 min read</p>
                            </div>
                        </div>
                    </div>
     
                    <Image variant="top" src={ corsali } className="blogpost-header-img"/>
                    <p><em>Corsali applies cutting edge technologies such as machine learning, cryptocurrencies, and low-bandwidth mobile development to provide economic opportunities to emerging markets. It accomplishes all this while addressing the corporate demand for outsourcing the architecting and training of machine learning models. By co-designing the experience with workers in Kenya and the Philippines, Corsali has enabled a mobile earn experience that fits with their lifestyles. Through this model, Corsali is able to attract the best workers and provide customized machine learning solutions for corporate clients that may require finer levels of expertise.</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is Corsali?</b></p>
                        <p><a className="blog-link" href="https://corsali.com/" target="_blank" rel="noopener noreferrer">Corsali</a> is a full stack machine learning platform that is powered by highly educated workers labeling data to earn crypto from their phones. Our mission is to make using machine learning effortless by empowering the next billion digital workers.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the founding story of Corsali?</b></p>
                        <p>There are a ton of interesting technical applications of cutting edge technology in emerging markets. I first got exposed to it through the MIT Bitcoin Club. I was mining crypto with old graphics cards I would find in the dumpster where labs would get rid of their equipment. I went on a club trip to explain Bitcoin to African regulators, and there someone from Zimbabwe showed me a photo of Zimbabwe dollars in the toilet and a sign that said "Please don't use Zimbabwe dollars as toilet paper." It was at this moment that I realized the potential of this technology. It could really be transformative for people living in other parts of the world. That is when I first got interested in applying emerging technologies to emerging markets.</p>
                        <Image src="https://storage.googleapis.com/vv-images/corsali_image1.jpeg" className="blogpost-img"/>
                        <p className="img-caption">The dumpsters at MIT - where Ann first got interested in crypto.</p>
                        <p>Around the same time, I was performing initial tests as part of a class out of the MIT Media Lab, trying to answer the question "how can we have somebody do work from their phone?" At the core of this was a desire for anybody in the world to be able to pick up their phone and work to lift themselves out of poverty. For this initial iteration, we had about 300 workers in the Philippines and East Timor labeling satellite imagery data of ships. We actually found people can do this as quickly as one could on a computer with great accuracy. The bottleneck to scale was a lack of international payments rails for easy cross-border payments.</p>
                    </div>
                    <Row className="blogpost-img-row">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/corsali_image2.png" className="blogpost-img"/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/corsali_image3.jpeg" className="blogpost-img"/>
                        </Col>
                        <p className="img-caption large-img-caption">Testing the mobile work app in Timor Leste (left) and Cebu in the Philippines (right) out of the MIT Media Lab pilot.</p>
                    </Row>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What problems are you solving for corporate clients?</b></p>
                        <p>Machine learning today is difficult to use. You have to think about everything from hiring a machine learning engineer, setting up a GPU instance to train models, architecting a neural network using PyTorch, knowing whether a model is finished, to deploying a model to an endpoint. And then there is also the human operation side. You have to figure out how to obtain a large quantity of example data to train the model, which usually involves a large team labeling your data. This huge upfront cost leads to many companies who could benefit from machine learning unable to use it for their business.</p>
                        <p>I think we're going to look back at this time when companies were creating their own machine learning models, and it will feel like when people were setting up their own servers. A few years ago, it made more sense to implement machine learning in house. Since this was all cutting edge, people did not know what were the best practices. Much like cloud servers have made running servers standardized and off-the-shelf, we aim to do this for machine learning solutions.</p>
                    </div>
                    <h4 className="blog-quote">"I think we're going to look back at this time when companies were creating their own machine learning models, and it will feel like when people were setting up their own servers."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What are the types of questions best answered by machine learning?</b></p>
                        <p>Something that amazes me is that if you ask most startup founders "what would you do if you could snap your fingers and have an ML model ready for production", they have a list of ML use cases ready to go. They range from predicting pricing in the travel industry to identifying specific objects in images, to categorizing users to allow for personalized experiences.</p>
                        <p>Another way to think about this is through the question: "if you gave a piece of data, detailed instructions and a specific question to 10 people in the US, would they give you the same answer?" For more industry specific questions, this extends to asking 10 experts in the field. If so, then it is a good fit for machine learning.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the role of "training data" with human workers? How does training data with human workers work?</b></p>
                        <p>Training data is a set of examples that allows the machine learning model to learn patterns in data rather than directly having to program a set of rules. For example, to build a machine learning model to identify whether there is a microwave in an image of a kitchen, we first have our workers label images of kitchens, identifying whether there is a microwave - this set of inputs (image of kitchen) and outputs (whether or not it contains a microwave) is the "training data". We then train a machine learning model on these examples to create an API endpoint that takes in an image of a kitchen and automatically outputs whether or not it contains a microwave.</p>
                        <p>In addition to returning this real-time prediction, we also send the new input to our workers to label if the model was unsure, thus continuing to improve the quality of the data model overtime and keep it up-to-date. In a world where the data evolves, actively keeping these models up-to-date requires a consistent human workflow.</p>
                    </div>
                    <Image src = "https://storage.googleapis.com/vv-images/corsali_image5.png" className="blogpost-img"/>
                    <p className="img-caption">Example flow for creating training and using a machine learning model through Corsali.</p>
                    {/* <Row className="blogpost-img-row">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = { corsali_image5 } className="blogpost-img"/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = { corsali_image6 } className="blogpost-img"/>
                        </Col>
                        <p className="img-caption large-img-caption">Training data is required to provide the machine learning algorithm with sample input questions along with the correct answers. The algorithm will then "learn" (or adjust) its algorithm to account for the new input. This requires that good training data is generated, and humans are often necessary to answer the questions of this initial data set.</p>
                    </Row> */}
                    <div className="interview-question">
                        <p><b className="interview-question-q">What opportunities does this model provide for workers?</b></p>
                        <p>There are many highly-educated, talented, young people in emerging markets that have not been able to find the opportunities to match that. The sorts of problems that machine learning is trying to answer are no longer simple questions such as "is this a cat or a dog?", - they are more complex and nuanced questions such as "what are the payment terms in this contract?" or "is the crack in this wall large enough to need a repair"? For this, you need someone with a fair amount of expertise on a particular topic.</p>
                        <p>So far, we have gotten feedback from workers like "I took my earnings this month and bought a blanket for my family" or "I was able to pay for my son's school fees this semester." For these individuals, Corsali has made a big difference. We are starting with a more educated demographic that addresses the market needs better, but long-term, the vision is for anyone to earn from their phone.</p>
                    </div>
                    <h4 className="blog-quote">"At the core of this was a desire for anybody in the world to be able to pick up their phone and work to lift themselves out of poverty."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Can you dive a bit deeper into the real-time payments model?</b></p>
                        <p>From the beginning, we were testing whether we can have somebody do work from their phone and earn money; however, it was very non-trivial to pay someone across borders. You need to set up a bank account in each country and maintain a relationship with each mobile money provider. If we wanted to do this, we would have to become a payments infrastructure company on top of a mobile work and machine learning company.</p>
                        <p>Real-time payments are essential for quickly building trust with the best workers. It enables us to reach workers that may be skeptical that they can earn income for completing the digital tasks. It was not until stable cryptocurrencies have become more wide-spread that we could provide instant payments that are akin to selling a physical product on the street.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How does Corsali compare to other existing companies in the space such as <a className="blog-link" href="https://scale.com/" target="_blank" rel="noopener noreferrer">Scale AI</a> or <a className="blog-link" href="https://www.sama.com/" target="_blank" rel="noopener noreferrer">Sama</a>?</b></p>
                        <p>First, these companies are focused just on training data. Essentially, these are tools to support your existing machine learning team who may have already identified this data to use for training. I would compare us more to a low-code ML tool like <a className="blog-link" href="https://angel.co/company/corsali/jobs" target="_blank" rel="noopener noreferrer">open roles</a>, or directly get in touch via email at <a className="blog-link" href="https://cloud.google.com/automl/" target="_blank" rel="noopener noreferrer">Google's AutoML</a>, where you bring a company from zero to one from a machine learning perspective with just an API request. What AutoML attempts to do by building very generic endpoints that support all machine learning questions actually does not work well in many cases because a good model needs a lot of context of the customer data. Customization and a human integration approach are necessary. Corsali adds that integrated human component. For example, a generic machine learning model from AutoML may be able to take an image and tell if it contains a stop sign or not, but it takes a lot more context for labeling whether there is a manufacturing defect on a product line.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How does the training model differ for the workers?</b></p>
                        <p>From the workers' side, these other companies often approached it by replicating the work culture of the US. One goes to an office and works at a computer from 9-to-5. But in Kenya or the Philippines, that does not really fit their lifestyle, and it does not get you access to the best workers who may be working other day jobs. In many of these countries, these individuals are mobile-first and faster at typing on their phones than the ergonomic keyboard. In college, they wrote their essays on their phones. We approach it by co-designing with the users. We introduce a task and ask what the best interface that can work for them. Having that flexibility of being able to work anywhere on one's own schedule from a phone, has helped our workers label very high quality data and do it quickly.</p>
                    </div>
                    <Image src="https://storage.googleapis.com/vv-images/corsali_image4.jpeg" className="blogpost-img"/>
                    <p className="img-caption">Corsali team members Phoebe and Will in Nairobi with Baptiste, a data labeler who helped co-design the labeling app.</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What was your life like growing up? In what ways did that experience influence you to start this company?</b></p>
                        <p>My first programming experience was on a graphing calculator in middle school. It was interesting to be able to write code once and then different people could play your game. From an economics perspective as a kid, I wanted to be chairman of the Federal Reserve. Janet Yellen was my hero. So, I worked for an economist at the Federal Reserve in high school, and I learned so much from him, particularly how to answer questions with data.</p>
                        <p>I've always been interested in research economics. How to improve economic outcomes at the country level is something that drives me. How can you enable as many people to act free of financial constraint? I see Corsali as the way to have that large-scale impact. I considered going the route of research economics, but it feels a lot slower in academia. In some cases, if you write up an economics paper, it may never get implemented in the real world, and it could take years to just be published. To be able to have impact in the same direction, but have it much faster is extremely rewarding.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What companies inspire you?</b></p>
                        <p>I am deeply inspired by Celo, which is an open source platform for global payments. The team did an incredible job building a system where interesting technical advancements can create large scale social impact. I also admire Stripe and how they've made it incredibly simple to accept online payments. People used to have to design and build payment rails themselves to accept credit card payments online. I see parallels with how machine learning is implemented today in that each company needs to set up its own infrastructure and models, but the actual implementation is very similar across companies.</p>
                        <p>I am also inspired by many companies bringing solar technology directly to consumers in parts of Africa. It exemplifies technological leapfrogging where new consumers of electricity skip over the iterative steps of innovation and in some cases start with the most cutting edge technology. Another example of this is mobile phones. People in emerging markets never really had landline phones, and low-end smartphone devices are becoming widespread. Similarly, our workers are often leapfrogging traditional finance, as their first investments are in cryptocurrency.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people get involved and support your effort?</b></p>
                        <p>We are hiring impact oriented software engineers and operators. You can look at our list of <a className="blog-link" href="https://angel.co/company/corsali/jobs" target="_blank" rel="noopener noreferrer">open roles</a>, or directly get in touch via email at <a className="blog-link" href="mailto:hello@corsali.com" target="_blank" rel="noopener noreferrer">hello@corsali.com</a>. We would also love to hear from anyone with a specific machine learning use case in mind or who resonates with our mission.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

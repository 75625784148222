//https://medium.com/@dmccoy/how-to-submit-an-html-form-to-google-sheets-without-google-forms-b833952cc175
export function sendEmail(email, signuptype, buttonId) {
    let signupbutton =  document.getElementById(buttonId)
    signupbutton.disabled = true
    signupbutton.textContent = "Hold tight"
    let url = "https://script.google.com/macros/s/AKfycbwynAVz9VAmNh5ocb5vft1MU4NkZbnM5MwLWOT8-vNsE5Zv10qndP6FScZK-9q5lNHh/exec?" + {signuptype}.signuptype + "=" + {email}.email
    return fetch(url, {
        "method": "GET",
        dataType: "json"
    })
        .then(response => {
            signupbutton.textContent = "Thanks! We'll be in touch soon."
            document.getElementById({signuptype}.signuptype).style.display = 'none'
            document.getElementById('signup-btn-div').style.marginLeft = 'auto'
            document.getElementById('signup-btn-div').style.marginRight = 'auto'
            console.log(response)
        })
            // response.json())
        .catch(err => {
            signupbutton.disabled = false
            signupbutton.textContent = "Request Access"
            console.log(err);
        });
}

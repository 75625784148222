import React from "react";
import Image from 'react-bootstrap/Image';
import top_10_mental_health from '../../tmpImages/top_10_mental_health.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function Top10MentalHealth() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Top 10 Mental Health Startups</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Top 10 Startups Innovating in Mental Health</h2>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Jul 19, 2021 ·</p>
                            </div>
                        </div>
                    </div>
                    <Image variant="top" src={ top_10_mental_health } className="blogpost-header-img"/>

                    <p><em>In <a className="blog-link" href="/company-search?category=Health%20%26%20Wellness&subcategory=Mental%20Health">expanding our database</a> of mission driven companies to include those focused on mental health, we pulled together some of our favorites and identified key market trends. Check out the market trend report <a className="blog-link" href="/blog/mental-health-trends">here</a>.</em></p>
                    <div className="interview-question">
                        <p>In no particular order, these companies stood out due to their innovative approaches, rapid growth demonstrated by fundraising and job opportunities, and traction demonstrated by app downloads and social following.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/coa.jpeg"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.joincoa.com/" target="_blank" rel="noopener noreferrer">Coa</a></p>
                        <p><b>Fundraising:</b> Seed | <b>Size:</b> 2–10 | <b>Founded:</b> 2019</p>
                        <p>Coa provides therapist-led emotional fitness classes and 1:1 therapy. It applies a community oriented approach, and leans into its live interactive classes which teach patients how to adopt skills for emotional fitness including self-awareness, play, empathy, curiosity, communication, resilience, and mindfulness.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/modern_health.jpeg"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.joinmodernhealth.com/" target="_blank" rel="noopener noreferrer">Modern Health</a></p>
                        <p><b>Fundraising:</b> Series D | <b>Size:</b> 201–500 | <b>Founded:</b> 2017</p>
                        <p>Modern Health makes value-based care easily accessible, personalized, culturally centered, and actionable for employers, all in one app. By providing mental health care as an employee benefit, it provides an affordable option to patients. It's also innovated in its tiered level service, providing self-service materials, coaches, group session, and licensed therapist depending on the level of care an individual needs.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/mindstrong.png"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://mindstrong.com/" target="_blank" rel="noopener noreferrer">MindStrong</a></p>
                        <p><b>Fundraising:</b> Series C | <b>Size:</b> 51–200 | <b>Founded:</b> 2014</p>
                        <p>MindStrong is committed to reshaping mental health through improvements in data measurement, data science, and virtual care models. MindStrong measures human-computer interaction and applies machine learning to develop breakthrough technology to detect and predict patient symptom evolution. This is integrated into a care platform to help guide providers to target proactive care.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/shine.png"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.theshineapp.com/" target="_blank" rel="noopener noreferrer">Shine</a></p>
                        <p><b>Fundraising:</b> Series A | <b>Size:</b> 11–50 | <b>Founded:</b> 2016</p>
                        <p>Shine is on a mission to make caring for mental and emotional health easier and more representative and inclusive. The founders "started Shine because we didn't see ourselves - a Black woman and a half-Japanese woman - and our experiences represented in mainstream 'wellness.'" It provides a self-care toolkit to help users learn daily self-care skills, personalized to them.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/real.jpeg"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.join-real.com/" target="_blank" rel="noopener noreferrer">Real</a></p>
                        <p><b>Fundraising:</b> Series A | <b>Size:</b> 11–50 | <b>Founded:</b> 2019</p>
                        <p>Real emphasizes preventative practices with a direct-to-consumer model. There is an affordable monthly membership fee that provides access to Therapy Pathways, in-person events, and therapist-led round table discussion. Their core product, Therapy Pathways, include guided on-demand sessions tailored to fit individuals mental health goals.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/lantern.jpeg"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://lantern.co/" target="_blank" rel="noopener noreferrer">Lantern</a></p>
                        <p><b>Fundraising:</b> Seed | <b>Size:</b> 2-10 | <b>Founded:</b> 2018</p>
                        <p>Lantern focuses specifically on the mental trauma and stress of losing a loved one to death. It has developed a step-by-step planning tool to navigate life before and after a death, so individuals can grieve easier.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/path.jpeg"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.pathccm.com/" target="_blank" rel="noopener noreferrer">Path</a></p>
                        <p><b>Size:</b> 11-50 | <b>Founded:</b> 2019</p>
                        <p>Path makes it easy to find a world class mental health provider that's covered by your insurance. Path is a network of mental health and addiction treatment providers. Their customers are health plans and employers, and they partner with the highest quality provider organizations like: behavioral health treatment hospitals, partial hospitalization programs, intensive outpatient programs, therapists, psychiatrists, case managers, coaches, and peer support programs.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/betterhelp.png"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.betterhelp.com/" target="_blank" rel="noopener noreferrer">BetterHelp</a></p>
                        <p><b>Size:</b> 51-200 | <b>Founded:</b> 2013</p>
                        <p>BetterHelp was one of the first virtual therapy platforms, founded in 2013 and having over 1M app downloads. Their mission is to provide everyone with an easy, affordable, and private access to professional counseling, anytime, anywhere. They even provide couples and teen counseling.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/calm.jpeg"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://www.calm.com/" target="_blank" rel="noopener noreferrer">Calm</a></p>
                        <p><b>Fundraising:</b> Series C | <b>Size:</b> 51-200 | <b>Founded:</b> 2012</p>
                        <p>One of the leaders in mindfulness guided audio content, Calm has over 10M app downloads. It's on our top list based on its impressive ability to continue to innovate with content including sleep stories, music, and celebrity partnerships. It's also been creative in providing access through various business model such as direct to consumer, business employee benefits, and through healthcare providers like <a className="blog-link" href="https://about.kaiserpermanente.org/our-story/news/announcements/calm-app-available-to-kaiser-permanente-members-at-no-cost" target="_blank" rel="noopener noreferrer">Kaiser Permanente</a>.</p>
                    </div>
                    <div className="interview-question">
                        <Image className="top-10-company-logo" src={"https://storage.googleapis.com/vv_logos/cerebral.png"}/>
                        <p className="top-10-company-title"><a className="blog-link" href="https://getcerebral.com/" target="_blank" rel="noopener noreferrer">Cerebral</a></p>
                        <p><b>Fundraising:</b> Series A | <b>Size:</b> 201–500 | <b>Founded:</b> 2020</p>
                        <p>One of the newest startups to the mental health scene, Cerebral launched in 2020, already raised $35M in funding with Series A, has several hundred employees with 206 job openings on LI. One of it's differentiators as a teletherapy platform is a focus on depression and anxiety disorders, combining prescription management and delivery.</p>
                    </div>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p>Check out more startups progressing mental healthcare in our <a className="blog-link" href="/company-search?category=Health%20%26%20Wellness&subcategory=Mental%20Health">company database</a> and sign up for our <a className="blog-link" href="/">newsletter</a>.</p>
                    </div>
                    {/* <div className="interview-question">
                        <p>Find this content useful? You can show your appreciation by buying the author a coffe.</p>
                        <a href="https://www.buymeacoffee.com/amyslawson"><Image className="buymeacoffee-img" src={"https://storage.googleapis.com/vv-images/amyslawson_coffee.jpeg"}/></a>
                    </div> */}
                </div>
            </div> 
        </div>
    );
}

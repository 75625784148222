import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import dispatch from '../../tmpImages/dispatch.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function DispatchGoods() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Dispatch Goods </title>
            </Helmet>
            <meta property="og:image" content={ dispatch } />
            <meta property="og:image:width" content="180" />
            <meta property="og:image:height" content="110" />
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Dispatch Goods</h2>
                        <h4 className="blogpost-subheader">How Dispatch Goods is changing the way we consume as a society.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">July 27, 2020 · 12 min read</p>
                            </div>
                        </div>
                    </div>
     
                    <Image variant="top" src={ dispatch } className="blogpost-header-img"/>
                    <p><em>I (virtually) sat down with Lindsey Hoell, CEO and founder of Dispatch Goods to talk about the company’s determination to change the way we consume as a society. One key takeaway was that <b>the status quo has been harming our planet</b>, and the solution requires new systems in place. Most impressively, the team has demonstrated an astounding ability to be nimble in these uncertain times of COVID-19 with its ability to experiment and pivot quickly. Read on to learn more and check out their current food delivery service in reusable containers for yourself!</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">The background story — What is Dispatch Goods and what inspired you to start it?</b></p>
                        <p>Dispatch Goods is creating a reusable alternative for restaurants that do a high volume of takeout and delivery.</p>
                        <p>In the beginning, I thought that the best way to tackle the adverse health effects of social isolation would be an app that would integrate with wearables like Fitbit or Apple watch. It would establish and manage data on a person's habits and then alert caregivers to any changes. In my family's example, it could have warned us when my grandfather was not doing well, and we could have driven up to visit.</p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/dispatch_image1.JPG" className="blogpost-img"/>
                                <p className="img-caption">Rainbow “sand” — on closer inspection reveals to be microplastics that had churned in the oceans.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>My husband and I are surfers, and this all started when we moved to Hawaii. At that time I had started a womens surfwear company, so Hawaii was a good spot for us. My husband and I were hiking to a pretty remote surf break on Kawaii. When we got down to the beach, the sand was rainbow-colored. I picked it up and realized it was microplastics, not sand. That’s when something clicked in my mind that this isn’t a problem of people littering on the beaches. This is a problem of a massive amount of waste just churning in our oceans.</p>
                            </Col>
                        </Row>
                        <p>After that I got involved with the Surfrider Foundation, and I ran a program called Ocean Friendly Restaurants where we helped restaurants transition to more sustainable practices. But we found that there just wasn’t a sustainable option for restaurants that did a lot of takeout and delivery, and I really wanted to solve that problem. I decided to completely change career paths and go back to school to get my MBA and try to find the intersection of sustainability and business. The beginning of last year we formed a team in a pre-accelerator at Berkeley, and then we launched in October with the Yelp headquarters as our testing ground for the first re-use system.</p>
                    </div>
                    <h4 className="blog-quote">"This isn’t a problem of people littering on the beaches. This is a problem of a massive amount of waste just churning in our oceans."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">So you went to business school specifically to start this company?</b></p>
                        <p>I went back to school to shift specifically into this waste problem. I didn’t know if I would start or find a company that was addressing that. Since I’d been an entrepreneur, I knew how much work it was. The first time I started a company, I was super naive. I thought it was going to be so fun. And it is so rewarding, but it is so much work. I had to take a couple deep breaths and be like, “okay, here we go again.” Because when I like something, I commit all in. It completely infiltrates my life.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What appeals to you about surfing?</b></p>
                        <p>When my brain is constantly thinking, it’s really nice to be in a space that it’s so gnarly that I can’t think. I can only concentrate on staying alive. It’s a really nice escape.</p>
                    </div>
                    <Image src="https://storage.googleapis.com/vv-images/dispatch_image2.jpg" className="blogpost-img"/>
                    <p/>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is your company’s mission?</b></p>
                        <p>It’s to change the way we consume as a society. We like to think about transitioning the onus from the consumer onto new systems because this is really a systems problem. We need a system solution, and Dispatch Goods has always felt as though we should look at the programs that have worked and take [their successes] to transition into something more sustainable. The model we’ve always looked towards is curbside recycling. Even though the actual circularity of it is less than desirable, the adoption in the participation in the system is high, even in places where it’s not government-mandated.</p>
                    </div>
                    <Row className="blogpost-img-row">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/dispatch_image3.jpeg" className="blogpost-img"/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/dispatch_image4.jpeg" className="blogpost-img"/>
                        </Col>
                    </Row>
                    <p/>
                    <div className="interview-question">
                        <p><b className="interview-question-q">It sounds like the mission is broader in scope than just like food delivery.</b></p>
                        <p>What we would like a Dispatch membership to mean is that you can get reusable containers from a lot of different places, and you know Dispatch is coming to get those containers to bring them where they need to go. Right now we are the platform that enables that [process] which allows us to learn about return rates and have a means of revenue in these early stages. But we don’t feel as though we need to be in between the customer and the product. We want to help enable wider spread adoption of circular systems by allowing companies to know that there’s a curbside pickup service that will get their containers back. That’s really the infrastructure that we’re looking to build.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What was your life growing up and did that experience motivate you to start a company in this space?</b></p>
                        <p>I grew up in the Midwest in Pickerington, OH, and there wasn’t a lot of environmental consciousness. Even though we recycled, we just were not as in touch. That being said, I think that is why I’m so confident that this is not only going to be applicable for the coast. I know Ohioans and midwesterners, and they want to do the right thing. There’s no government mandate for them to have curbside recycling, and still over 50% of Ohioans pay for that added service.</p>
                        <p>I push back when investors tell me that this is only going to be a coastal solution. When I go back [to Ohio], I see that compostable straws are now a thing which are more expensive for restaurants, and I see there’s a refill shop opening in Columbus. So I think that my roots have really helped give me confidence to say “this might start on the coast, but Middle America wants this too.”</p>
                    </div>
                    <h4 className="blog-quote">"If you do what you love, you find yourself working around the clock."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What have been your most successful pilots and your biggest learnings?</b></p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/dispatch_image5.jpg" className="blogpost-img"/>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>The very first one was the Yelp pilot, and that gave us an understanding that there was demand for this. Their employees could request our containers at the lunch spots that they frequented the most. We put return bins on every floor of the Yelp buildings, and we put little signs at three restaurant partners and a food truck. This was not even a model we were planning to replicate. We just wanted to figure out “Do these containers work? Do people want to use this? How is the dishwashing going to work? What are the health department processes?” But what happened was company after company contacted us after they saw the signs and were like “hey can we get this for our employees?”</p>
                            </Col>
                        </Row>
                        <p>[For] the biggest learnings, I think COVID has been a brutal teacher that you have to be nimble. Because we were rolling out with companies in that way, we were pretty secure with what the rest of the year was going to look like. Then everything came to a screeching halt, and we had to figure out how to pivot quickly into home delivery, which was a whole other challenge. Additionally, most of our restaurant partners closed because they were catering to the downtown lunch crowd. So in a lot of ways, we had to start over. Trying to be nimble and learning as much as we can as cheaply as possible is how Dispatch will hopefully survive this big disruption.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Can you describe a bit of the company culture?</b></p>
                        <p>I think it’s pretty fun. We definitely feel like a family. When COVID hit and our [corporate] contracts were delayed, it was a really scary time. My biggest fear was that I was going to lose the team because our team is everything. [It showed] just how committed the team is to solving this problem. Everyone was like, “no, we’re sticking with it.” It’s just a bunch of people that believe that we have to do this because the planet needs it, and we’re going to figure out how to do it. People are varying degrees of environmentalists on the team, but everyone really believes that we’re doing good.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">When working at a company where the mission really resonates so much with your values and beliefs, how do you ensure that you don’t get burned out?</b></p>
                        <p>I don’t know that we perfectly struck that chord. It is one of the reasons why we decided to create a for-profit company and not a non-profit. Because while we can’t compensate ourselves well right now, we do hope that we’re going to be able to pay good living wages. It’s so often the case that women are in those nonprofit and impact roles. I firmly believe that we are providing something very valuable to a lot of different stakeholders in the supply chain and waste management circles, and there’s enough value to create a business that we can pay people similar to what they would be paid at a tech company.</p>
                        <p>If you talk to any startup, even a non-impact startup, trying to figure out how to not burn out is a challenge. We try to laugh. Andrew, my husband, tried to see who could make the fanciest toast one morning. And we try to at least take pauses and enjoy each other’s company. But in the end, the reason that we’re a for-profit company is to structure something that is compensating people for what they’re worth and being able to create a work-life balance that’s more in line with industry standard.</p>
                        <p>One of our advisors said “the saying is do what you love and you’ll never work a day in your life. But it’s the complete opposite. If you do what you love, you find yourself working around the clock.” I think that is the case for us right now, but of course we hope when we become a more mature company to have better balance.</p>
                    </div>
                    <h4 className="blog-quote">"It’s just a bunch of people that believe that we have to do this because the planet needs it."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What do you see is the intersection between social equity and your mission?</b></p>
                        <p>The equity component is incredibly important to us. We’re already a female-founded company, so that feels empowering. And we’ve noticed that this movement is largely female-driven too.</p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/dispatch_image6.png" className="blogpost-img"/>
                                <p className="img-caption">The founding team has done every role in the company including delivery in order to gather customer feedback and identify areas for process improvement.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>It even goes beyond compensation. It’s in the way that you treat people in the job that they do regardless of what it is and allowing them ownership of things. I used to do the dishwashing at the beginning because we didn’t know if we had a company. So when we hired our first dishwasher, I was incredibly grateful that someone else was going to help me with this work. Since the founding team has done every role in this company, our gratitude for people coming in and taking a piece of that work off our plate helps them feel as though they’re seen and that their work is appreciated.</p>
                                <p>I think that the Millennial and Gen Z consumers want to know who you’re hiring and how they’re getting paid and that you are being thoughtful in representation. So, I also think it’s good business. It’s good for our brand, and it’s good for us being able to sleep at night.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What are some of the advantages and unique challenges that have come with building a company that’s so mission-driven?</b></p>
                        <p>It feels like there are a lot of hands trying to move this mountain. So we have a lot of people that stick their neck out for us constantly because they want us to be successful. I don’t think that we would be nearly as far as we are without all of those people who constantly say, “hey, let me introduce you to this person.”</p>
                        <p>With investment, it’s a little more difficult because [Venture Capitalists] generally want the same returns even if they say they want to invest in an impact company. We also have to think a lot harder. We can’t just go with what everybody else is doing because the status quo is bad for the planet. [For example], a lot of food delivery apps do flyers, and we couldn’t do flyers as a zero-waste company. We could print flyers on paper that you could plant and flowers grow, but it’s really expensive. So we had to be very precise about what houses got the seed paper. For every decision, we have to weigh the opportunity against the environmental cost.</p>
                        <p>But here’s the thing: our customers recognize that we are cognizant of all of these pieces, and that creates more brand loyalty. I think that in the end, it’s a plus because of the people that we’re attracting to be part of the Dispatch community.</p>
                    </div>
                    <h4 className="blog-quote">"It’s in the way that you treat people in the job that they do regardless of what it is."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What areas do you see as challenges when expanding your customer base?</b></p>
                        <p>Absolutely convenience. The delivery time window is too large, people don’t like how far in advance they have to plan. I think if we add a grocery option, people expect for their groceries to take a day. For restaurant food, they expect a different experience.</p>
                        <p>We have what we call the dark green customer who knows every intricacy about the decisions we’re making. And then we have what we call the light green consumer who will participate in more sustainable options if it’s convenient enough and reasonably priced.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What about those who are not green at all?</b></p>
                        <p>We have [data on] the customers that tried it once and kept ordering and the ones that didn’t. We are listening [to feedback]. We don’t want to just be a niche company to a small subset of consumers. We want to make a big change, so we have to listen to the people that won’t do it if it’s not convenient enough.</p>
                        <p>That’s probably 25% [of the population]. Then you have to work with policymakers. There’s some people that won’t convert until they’re forced to. So, we can show policymakers that there’s an infrastructure that can handle the volume that’s needed to make these policies. And then we can talk to the municipalities about subsidizing and helping citizens participate in a program like this since it will save them a lot [of money] in waste management.</p>
                    </div>
                    <div className="interview-question">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p><b className="interview-question-q">What’s in store for Dispatch Goods?</b></p>
                                <p>We’re going to be testing two things in the next month. One is going to be enabling people to get hot food. The other is adding more restaurant offerings with potentially some things you might expect at your grocery store. We’re still in this space where we’re prototyping and testing really quickly. We get feedback constantly, and it’s so helpful.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/dispatch_image7.jpg" className="blogpost-img"/>
                            </Col>
                        </Row>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What companies inspire you?</b></p>
                        <p>I like Impossible Food because I feel like [the founder] feels the same way as us — that if you make things comparable enough at the beginning, people will even pay a little bit more to do the right thing. The Impossible burger was a great example because he didn’t take a bet on vegans. He took a bet that non-vegans would eat meatless hamburgers. His mission is big — to decrease meat consumption.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people get involved and support your effort?</b></p>
                        <p>People voting with their dollars helps a lot. Policy will follow the system, so if we can show that there’s enough of a grassroots movement towards shifting to reusables, then we’re going to be well situated to do this at scale. Support Dispatch and other zero-waste initiatives, tell your representatives that you want policies that support it and that companies should be held accountable for the externalities of single-use [products] rather than taxpayers. Also tell restaurants and your friends about us. Word-of-mouth goes a long way.</p>
                        <p>You can try out the delivery app service at our <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://dispatchgoods.com/home">website</a> and use AMY10 discount code for $10 off your first delivery. Let me know what you think!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

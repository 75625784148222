import React from "react";
import { useLocation } from 'react-router-dom'


export function Contact() {
    return <div>
        <h1>[Contact]</h1>
    </div>
}

export function Whoops404() {
    let location = useLocation();
    return (
        <div>
            <h1>Resource not found at {location.pathname}</h1>
        </div>
    )
}

export function Warmup() {
    return <div>
        <h1>200</h1>
    </div>
}

import Card from 'react-bootstrap/Card';

export function BlogCard({imageSrc, title, description, link}) {
    return (
    <Card className="blogpost-card h-100">
        <Card.Img variant="top" src={ imageSrc } className="blogpost-card-img"/>
        <Card.Body>
            <h2 className="blogcard-text">{ title }</h2>
            <Card.Text>
            { description }
            </Card.Text>
            → <a className="generic-link blog-card-link" href={link}>Read More</a>
        </Card.Body>
    </Card>);
}
import React from "react";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Helmet } from "react-helmet";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { sendEmail } from '../components/SendEmail'
import jobseeker_image from '../tmpImages/jobseeker_image.png';
import category_icons from '../tmpImages/category_icons.png';
// import working_woman from '../tmpImages/working_woman.jpg';
import Image from 'react-bootstrap/Image';


export function JobSeeker({navColor}) {
    navColor("green-nav")
    const [email, setEmail] = useState('')

    const handleEmailChange = event => {
        setEmail(event.target.value)
    };

    const handleSubmit = event => {
        event.preventDefault()
        sendEmail(email, "jobseeker-email")
    };
    return (
        <div className="greendrop-page">
            <Helmet>
                <title>Virtuous Ventures - Job Seeker</title>
            </Helmet>
            <div className="waitlist-signup-input">
                <h3 className="find-job-header">
                    Find a job that aligns with your values
                </h3>
                <h5 className="find-job-subheader ">
                    The Service for Matching Talent with Purpose
                </h5>
                <form onSubmit={handleSubmit}>
                {/* <form action="https://script.google.com/macros/s/AKfycbwynAVz9VAmNh5ocb5vft1MU4NkZbnM5MwLWOT8-vNsE5Zv10qndP6FScZK-9q5lNHh/exec"> */}
                <InputGroup size="lg" className="mb-3 signup-group">
                    <FormControl
                    aria-label="Recipient's email"
                    placeholder="Enter your email.."
                    className = "signup-input"
                    id="jobseeker-email" name="jobseeker-email"
                    onChange={handleEmailChange}
                    value={email}
                    type="email" required
                    />
                    <InputGroup.Append className="signup-btn-div" id="signup-btn-div">
                        <Button variant="outline-secondary" id="sign-up-button" className="sign-up-button category-button" type="submit">Request Access</Button>
                    </InputGroup.Append>
                </InputGroup>
                </form>
            </div>
            <div>
                <Image className="jobseeker_image" src={ jobseeker_image }/>
            </div>
            {/* <div className="working-woman-div">
                <Image className="working-woman-img" src={ working_woman }/>
            </div> */}
            <div className="category_icon_div">
                <p className="category_choice">What's Your Cause?</p>
                <Image className="category_icons" src={ category_icons }/>

                {/* <Image src = "https://storage.googleapis.com/vv-images/companysearch_image3.png" className="blogpost-img"/> */}
            </div>
        </div>
    );
}
import { TwitterLogo, MediumLogo, InstagramLogo, LinkedInLogo } from './SocialLogos'

export function Footer() {
    return <div className="footer">
        <div className="social-logos-footer">
            <MediumLogo/>
            <InstagramLogo/>
            <LinkedInLogo/>
            <TwitterLogo/>
        </div>
    </div>
}
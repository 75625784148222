import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Contact, Whoops404, Warmup} from './pages';
import { Edlyft } from "./pages/blog/Edlyft";
import { CompanySearch } from "./pages/CompanySearch";
import { Home } from "./pages/Home";
import { About } from './pages/About'
import { EmergingImpact } from "./pages/blog/EmergingImpact";
import { Beginning } from "./pages/blog/Beginning";
import { ModernHealth } from "./pages/blog/ModernHealth";
import { Pachama } from "./pages/blog/Pachama";
import { Corsali } from "./pages/blog/Corsali";
import { DispatchGoods } from "./pages/blog/DispatchGoods";
import { CompanySearchAnnounce } from "./pages/blog/CompanySearch";
import { VVNavbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { JobSeeker } from './pages/JobSeeker';
import { MentalHealthTrends } from './pages/blog/MentalHealthTrends.js'
import { Top10MentalHealth } from './pages/blog/Top10MentalHealth.js'
import { TeachingToTech } from './pages/blog/TeachingToTech.js'
import { Welbi } from "./pages/blog/Welbi";
import { Blog } from './pages/Blog.js'
import { Jobs } from "./pages/Jobs";
import { useState } from 'react';


function App() {
  const [navColor, setNavColor] = useState('')

  return (
    <div>
      <VVNavbar colorClassName={navColor}/>
      <Routes>
        <Route path="/" element={<Home navColor={setNavColor}/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/company-search" element={<CompanySearch navColor={setNavColor}/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/blog">
          <Route path="/blog/" element={<Blog/>}/>
          <Route path="/blog/welbi" element={<Welbi/>}/>
          <Route path="/blog/top-10-mental-health" element={<Top10MentalHealth/>}/>
          <Route path="/blog/mental-health-trends" element={<MentalHealthTrends/>}/>
          <Route path="/blog/corsali" element={<Corsali/>}/>
          <Route path="/blog/company-search-announce" element={<CompanySearchAnnounce/>}/>
          <Route path="/blog/pachama" element={<Pachama/>}/>
          <Route path="/blog/edlyft" element={<Edlyft/>}/>
          <Route path="/blog/modernhealth" element={<ModernHealth/>}/>
          <Route path="/blog/emergingimpact" element={<EmergingImpact/>}/>
          <Route path="/blog/beginning-of-vv" element={<Beginning/>}/>
          <Route path="/blog/dispatch-goods" element={<DispatchGoods/>}/>
          <Route path="/blog/teaching-to-tech" element={<TeachingToTech/>}/>
        </Route>
        <Route path="*" element={<Whoops404/>}/>
        <Route path="_ah">
          <Route path="warmup" element={<Warmup/>}/>
        </Route>
        <Route path="/jobseeker" element={<JobSeeker navColor={setNavColor}/>}/>
        <Route path="/jobs" element={<Jobs/>}/>
      </Routes>
      <Footer/>
    </div>
  )
}

export default App;

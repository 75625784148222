export function TwitterLogo () {
    return (
        <a href="https://twitter.com/virtuousventure" target="_blank" rel="noreferrer">
            <svg className="social-logos-header-item" version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 700.000000 569.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,569.000000) scale(0.100000,-0.100000)" fill="#013950" stroke="none">
                <path d="M4605 5665 c-127 -22 -250 -62 -370 -119 -244 -116 -424 -270 -574
                -491 -118 -173 -177 -312 -222 -525 -28 -132 -32 -410 -7 -527 l15 -73 -52 0
                c-28 0 -101 5 -161 11 -469 46 -929 178 -1369 394 -461 226 -856 519 -1194
                885 -58 63 -123 135 -143 160 l-37 45 -49 -105 c-51 -108 -87 -214 -119 -355
                -25 -113 -25 -405 0 -525 27 -128 92 -314 143 -409 91 -172 227 -336 374 -451
                44 -35 80 -66 80 -68 0 -9 -157 10 -250 30 -114 24 -226 64 -315 111 -36 19
                -67 32 -68 28 -8 -14 13 -231 27 -296 95 -410 341 -754 686 -955 84 -49 264
                -123 353 -146 38 -9 65 -20 60 -25 -4 -4 -51 -15 -103 -25 -110 -20 -388 -24
                -460 -6 -25 6 -48 8 -51 4 -9 -9 59 -174 105 -256 157 -281 454 -535 746 -640
                115 -42 265 -75 368 -82 51 -4 95 -10 97 -14 7 -11 -150 -120 -290 -203 -481
                -284 -1023 -424 -1565 -403 -91 3 -183 9 -204 12 -59 10 -41 -5 96 -84 544
                -311 1118 -492 1722 -542 186 -16 669 -7 846 15 342 42 712 132 990 240 341
                133 641 300 932 518 769 575 1326 1449 1537 2412 72 327 91 483 98 803 l6 263
                116 93 c149 120 369 346 493 506 53 69 94 128 92 131 -2 4 -58 -16 -124 -43
                -66 -27 -177 -64 -248 -84 -134 -36 -394 -88 -414 -82 -6 2 27 31 73 63 211
                148 404 396 498 640 16 41 27 76 24 79 -3 2 -21 -7 -41 -21 -54 -36 -171 -94
                -312 -153 -169 -71 -241 -95 -404 -135 l-139 -35 -76 69 c-197 176 -412 292
                -650 348 -89 21 -137 25 -296 28 -134 3 -213 0 -270 -10z"></path>
            </g>
            </svg>
        </a>
    );
}

export function MediumLogo () {
    return (
        <a href="https://medium.com/virtuous-ventures" target="_blank" rel="noreferrer">
            <svg className="social-logos-header-item" version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 700.000000 555.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,555.000000) scale(0.100000,-0.100000)" fill="#013950" stroke="none">
                    <path d="M97 5533 c-4 -3 -7 -28 -7 -55 0 -40 5 -54 33 -85 36 -40 214 -254
                    432 -519 72 -87 154 -184 184 -217 29 -32 63 -80 75 -106 l21 -46 0 -1665 0
                    -1665 -21 -45 c-18 -39 -178 -236 -690 -853 l-114 -137 0 -60 0 -60 1040 0
                    1040 0 0 56 0 56 -137 166 c-76 91 -172 207 -213 257 -41 51 -145 176 -230
                    279 -213 256 -217 263 -240 335 -20 62 -20 90 -20 1439 0 877 3 1373 10 1366
                    5 -5 36 -68 68 -139 33 -72 110 -240 172 -375 178 -387 334 -726 445 -970 56
                    -124 119 -261 140 -305 21 -44 70 -152 110 -240 40 -88 128 -279 195 -425 67
                    -146 166 -362 220 -480 54 -118 155 -339 225 -490 70 -151 152 -332 183 -403
                    l57 -127 109 2 109 3 107 270 c173 436 304 768 373 945 36 91 115 293 177 450
                    112 283 215 546 429 1088 151 383 315 800 400 1016 39 98 73 181 76 184 11 11
                    5 -3214 -6 -3246 -7 -19 -117 -134 -307 -319 l-297 -288 -3 -57 -3 -58 1371 0
                    1371 0 -3 58 -3 57 -285 280 c-157 154 -293 295 -302 315 -16 33 -17 163 -17
                    2055 0 1891 1 2022 17 2056 10 22 131 145 309 315 l293 279 0 58 0 57 -966 -2
                    -966 -3 -309 -770 c-170 -423 -347 -864 -393 -980 -46 -115 -186 -464 -311
                    -775 -125 -311 -254 -632 -287 -715 -33 -82 -63 -154 -68 -159 -9 -9 -30 33
                    -175 354 -51 113 -110 241 -130 285 -21 44 -70 152 -110 240 -40 88 -134 295
                    -210 460 -75 165 -179 392 -230 505 -51 113 -144 315 -205 450 -260 569 -336
                    735 -412 905 -45 99 -86 186 -91 193 -7 9 -223 12 -1017 12 -553 0 -1010 -3
                    -1013 -7z"></path>
                </g>
            </svg>
        </a>
    );
}

export function InstagramLogo () {
    return (
        <a href="https://www.instagram.com/virtuous_venture/" target="_blank" rel="noreferrer">
            <svg className="social-logos-header-item" version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 700.000000 700.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)" fill="#013950" stroke="none">
                    <path d="M2210 6979 c-489 -21 -720 -62 -1041 -188 -204 -80 -397 -210 -574
                    -386 -228 -227 -367 -466 -463 -795 -107 -363 -122 -631 -122 -2113 0 -1450
                    15 -1734 112 -2072 100 -354 239 -596 473 -830 251 -250 499 -387 886 -488
                    313 -82 617 -97 2019 -97 1484 0 1752 15 2101 120 351 105 573 234 804 465
                    229 230 354 443 461 789 107 347 124 630 124 2113 0 1482 -15 1750 -122 2113
                    -96 329 -235 567 -463 795 -180 180 -364 303 -582 388 -329 129 -540 166
                    -1062 187 -290 11 -2272 11 -2551 -1z m2545 -629 c371 -16 528 -36 705 -92
                    213 -67 391 -176 529 -324 214 -229 318 -516 352 -969 7 -92 13 -586 16 -1275
                    7 -1643 -7 -1906 -122 -2216 -177 -477 -552 -742 -1135 -803 -244 -26 -684
                    -33 -1790 -28 -1173 5 -1316 9 -1550 48 -500 83 -833 345 -995 783 -115 310
                    -129 573 -122 2221 5 1160 9 1307 47 1542 72 448 310 785 672 952 231 107 457
                    146 948 164 215 8 2239 5 2445 -3z"></path>
                    <path d="M5234 5762 c-116 -41 -199 -116 -251 -228 -25 -53 -28 -70 -28 -169
                    0 -102 2 -114 31 -175 46 -95 101 -153 192 -197 74 -37 79 -38 186 -38 104 0
                    115 2 178 32 86 41 155 109 199 198 32 66 34 75 34 180 0 105 -2 113 -34 179
                    -43 89 -109 154 -197 197 -61 29 -80 33 -164 36 -73 3 -107 -1 -146 -15z"></path>
                    <path d="M3243 5274 c-332 -48 -631 -181 -888 -395 -299 -250 -517 -599 -600
                    -962 -59 -259 -59 -575 0 -834 40 -174 134 -395 236 -553 249 -385 659 -676
                    1092 -775 316 -72 677 -57 982 41 103 33 319 138 415 201 217 143 436 371 561
                    585 171 292 249 580 249 918 0 475 -166 891 -493 1236 -233 247 -560 436 -880
                    509 -196 45 -482 57 -674 29z m463 -629 c440 -77 807 -417 920 -852 35 -134
                    44 -347 20 -489 -83 -483 -461 -865 -940 -949 -113 -19 -299 -19 -412 0 -413
                    73 -763 375 -898 776 -75 223 -75 514 0 738 133 397 483 701 891 775 106 19
                    310 20 419 1z"></path>
                </g>
            </svg>
        </a>
    );
}

export function LinkedInLogo() {
    return (
        <a href=" https://www.linkedin.com/company/virtuous-ventures/" target="_blank" rel="noreferrer">
            <svg className="social-logos-header-item" version="1.0" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 700.000000 700.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,700.000000) scale(0.100000,-0.100000)" fill="#013950" stroke="none">
                    <path d="M655 6975 c-121 -27 -224 -77 -343 -166 -110 -83 -227 -258 -270
                    -403 -33 -113 -41 -308 -18 -425 65 -319 299 -562 622 -646 263 -68 581 12
                    780 198 169 158 257 343 270 572 25 405 -238 765 -630 865 -94 24 -313 26
                    -411 5z"></path>
                    <path d="M5029 4775 c-348 -55 -704 -241 -938 -491 -72 -77 -144 -171 -172
                    -226 -11 -21 -24 -38 -29 -38 -6 0 -10 118 -10 320 l0 320 -705 -2 c-388 -2
                    -705 -6 -705 -10 0 -5 0 -1050 0 -2323 l0 -2315 730 0 729 0 4 1347 3 1348 22
                    98 c30 128 60 209 113 302 116 203 281 333 504 397 68 19 102 23 230 22 130
                    -1 161 -4 232 -26 108 -33 181 -78 263 -165 108 -113 166 -251 207 -488 15
                    -90 17 -235 20 -1465 l4 -1365 730 -3 730 -2 -4 1597 c-3 1469 -4 1606 -20
                    1703 -106 634 -390 1061 -863 1298 -173 87 -372 146 -574 171 -115 15 -396 12
                    -501 -4z"></path>
                    <path d="M127 4663 c-4 -3 -7 -1052 -7 -2330 l0 -2323 730 2 730 3 0 2325 0
                    2325 -723 3 c-398 1 -727 -1 -730 -5z"></path>
                </g>
            </svg>
        </a>
    );
}
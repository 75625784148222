import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import pachama from '../../tmpImages/pachama.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"

export function Pachama() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Pachama</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Pachama</h2>
                        <h4 className="blogpost-subheader">Applying Artificial Intelligence to measure forest carbon sequestration.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Mar 7, 2021 · 10 min read</p>
                            </div>
                        </div>
                    </div>
                    <Image variant="top" src={ pachama } className="blogpost-header-img"/>

                    <p><em>The environmental crisis is one of the biggest issues plaguing our generation. With the New York Times recent publishing of The Great Climate Migration , it is clear that climate change has already begun to drastically impact the lives of humans and all other forms of life. Action is necessary to not only reduce emissions, but to reverse the damage that has already been done. We will need the brightest minds and latest technologies to be directed toward solutions. I chatted with Diego Saez-Gil, Co-Founder and CEO of Pachama on how they employ Artificial Intelligence to measure a forest’s carbon sequestration from satellite images. With this they have created a marketplace for companies and consumers to purchase verified carbon offsets, yet this is just the start of his vision with Pachama!</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Let’s jump into the background story. What inspired you to start Pachama?</b></p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/pachama_image1.jpeg" className="blogpost-img"/>
                                <p className="img-caption">The Yunga cloud-forest that surrounded Diego’s hometown in Tucuman, Argentina.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>I grew up in Northern Argentina surrounded by beautiful forests. So as a kid, I had this love for nature. My first entrepreneurial adventure was in high school when I was part of a team that designed a garbage recycling plant for my city and presented it to a mayor. I did my masters in Europe and an internship in the US, and from there decided I wanted to become an entrepreneur. I started two companies related to travel because that was what I was doing, so I was very aware of the problems of a traveler. But I always had this idea in the back of my head that I want to start a company that tackles climate change and the environmental crises. I believe these are the biggest problems that humanity faces. In a way, all other problems are secondary if we do not have a planet on which to solve them.</p>
                            </Col>
                        </Row>
                        <p>After I exited the last company, I came back to Argentina to get inspiration. My brothers and I traveled through Bolivia and Peru. I spent some time in the Amazon Rainforest with my notebook, and I observed this process of carbon sequestration and ecosystem services that the forest has been providing for many years. The forests have enormous potential to solve climate change, yet humans continue to destroy them. I recognized that here we had the solution to climate change, we just needed to put the best engineering, the best science, and the best entrepreneurship in the service of restoring forests. I came back to California and reconnected with Tomas, my CTO and co-founder, and we got started.</p>    
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the company’s mission?</b></p>
                        <p>To me the purpose is something that is timeless. The purpose of the company is to protect our living planet. Hopefully one day we solve climate change, but we will always need to continue protecting our home. The mission is something that we aspire to accomplish. Our mission is to restore nature to solve climate change. We have too much CO2 in the atmosphere, and restoring nature has the potential of drawing down hundreds of gigatons of CO2.</p>
                    </div>
                    <h4 className="blog-quote">“I believe [the environmental crises] are the biggest problems that humanity faces. In a way, all other problems are secondary if we do not have a planet on which to solve them.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How is Pachama working to achieve that mission?</b></p>
                        <p>The reason humans are not conserving and restoring forest is not because we do not know how. It is because nobody is paying for it. So this is actually more of a financial problem. Meanwhile, there are all these corporations and governments that are pledging to achieve “Net Zero” emission (aka “Carbon Neutrality”). Net Zero means measuring your emissions, reducing your emissions, and compensating what you cannot immediately reduce. Right now, that compensation is done through carbon markets, a mechanism by which projects reducing emissions or sequestering carbon can sell carbon credits. Until very recently, this was a small market with concerns around integrity and accountability, and it lacked clarity in its function. Pachama is addressing these issues using satellite images and Artificial Intelligence (AI) to validate the existence of a forest, that trees are being planted and that the carbon is being sequestered. Automating this at a global scale can improve the transparency and efficiency of the market and convince more companies to invest.</p>
                        <Image src = "https://storage.googleapis.com/vv-images/pachama_image2.gif" className="blogpost-img"/>
                        <p className="img-caption">Satellite image processing using artificial intelligence to monitor forest carbon sequestration.</p>
                        <p>Through this validation, capital can also flow more seamlessly to the people on the ground who are doing the work. We have already identified and validated land owned by people that could be employed for forest regeneration, but these owners do not know how to start with carbon credits. If we can make it seamless for them to come and participate in this market, we can unlock a lot of potential carbon sequestration.</p>
                    </div>
                    <h4 className="blog-quote">“Hopefully one day we solve climate change, but we will always need to continue protecting our home.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Without strict regulations, why are customers buying carbon credits?</b></p>
                        <p>A lot of publicly traded companies are receiving pressure from multiple stakeholders, and they do care about taking environmental responsibility. Investors like Black Rock are demanding all their companies to be carbon neutral. There is pressure from employees such as the 10,000 that signed a letter to Jeff Bezos asking that Amazon becomes carbon neutral. Even customers and consumers are demanding it. Regulations are also part of the reason, as certain countries already require companies to offset their carbon.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Why are you starting with trees?</b></p>
                        <p>At the beginning of carbon markets, there was a focus on reducing emissions (eg. funding project in the clean energy sector). But what is needed now is removing carbon from the atmosphere. There are a few ways that we can remove carbon. Some of them are artificial such as Director Air Capture (DAC). Elon Musk recently launched a prize to incentivize more of these ideas, but that development is still very nascent. Then, we have natural ways of drawing down carbon. The planet has already developed a technology called photosynthesis that works marvelously, and oceans can also have a huge potential for carbon sequestration.</p>
                        <p>Though we are not ruling out expanding to other solutions in the future, we started with trees because it is the most impactful. Trees are a keystone species. A tree starts a forest, and the forest is an ecosystem that supports a myriad of life. Forests provide many other benefits from generating clouds that fertilize the surrounding areas, to providing sources of income for local communities.</p>
                    </div>
                    <Image src = "https://storage.googleapis.com/vv-images/pachama_image3.jpeg" className="blogpost-img"/>
                    <p className="img-caption">Diego with co-founder Tomas.</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can carbon offsetting be attractive for landowners given the opportunity cost of agriculture or ranching?</b></p>
                        <p>That comes down to the price of carbon. The problem in the past is that the price per ton of carbon was very low. But now the price is rising to make it competitive with other land uses such as agriculture and cattle ranching. We are seeing a lot of interest in places like Brazil, Peru, and Argentina to use the land for carbon sequestration. It is very exciting that this can become a profitable activity for landowners.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the approach toward reforestation versus forest preservation?</b></p>
                        <p>It would not make any sense to be planting trees and lose the Amazon Rainforest. It is important to also invest in avoidance of deforestation projects. Mature forests do not sequester that much carbon, but these projects are very important from an ecological perspective. Some companies want to focus more on the ecological value, so we offer opportunities for companies to invest in both types of projects to offset their carbon footprint.</p>
                    </div>
                    <h4 className="blog-quote">“A tree starts a forest, and the forest is an ecosystem that supports a myriad of life.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the vision for the future of Pachama?</b></p>
                        <p>From a company perspective, I hope that this becomes a vehicle for a lot of talented scientists, engineers, entrepreneurs and artists that want to dedicate their life to protecting the planet. From a business perspective, I imagine that we can become a platform that not only connects supply and demand on carbon markets, but that uses the data collected to process and provide other services. If we are to solve climate change and manage the planet sustainably, we need to know where to plant agriculture, where to reforest, and where to build cities. Pachama will have the geospatial data and intelligence to provide insights on all those fronts. So, the vision is to be an environmental data platform in addition to a marketplace.</p>
                        <p>For example, the way we decide where to do agriculture is not based on data. We are cutting down a primary rainforest to plant soy while there are other places we could do so without deforestation. We are also planting crops in places where they will not get the highest yield. How we manage all the resources on Earth should be optimized by artificial intelligence because no human intelligence can.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What do you see as the future for carbon credits?</b></p>
                        <p>I imagine that the same way that consumers are looking for organic food, consumers will be asking for Net Zero products. GM just announced that they’re going to be Net Zero by 2035 and will no longer sell gas cars. We are going to see an entire transformation of the economy towards Net Zero. During the next two decades, I think we will see a lot of capital flow into carbon markets to compensate for the emissions while the economy transitions to a true zero-emissions world.</p>
                        <p>That being said, Pachama is not just about carbon credits. We are about forest restoration to carbon removal. Even if we reach zero emission, we still should work to remove carbon from the atmosphere to restore ecosystems.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What challenges have been unique to the climate tech space?</b></p>
                        <p>When we started the company over two years ago, the challenge was being confused for a non-profit. When talking to investors, we needed to demonstrate our business model and that it was actually profitable. Fortunately, there has been a climate tech movement in Silicon Valley, and investors are already convinced that companies solving climate change can become successful businesses.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How did your upbringing influence you to become an entrepreneur?</b></p>
                        <p>I grew up in a very small city in Argentina. I recently visited, and it felt surreal that I made it all the way to Silicon Valley from there. As a kid, I always had a lot of curiosity which my family fostered. My dad is an architect and my mom is a biochemist, so there were a lot of books in our house. Every time that I wanted to start a project, my dad would give me books to read about it. We also had one of the first computers in town, and I was programming with Basics at 12 years old. Even though Argentina is a country that is very disconnected from the world in many senses, my upbringing gave me a lot of self-confidence that I could try things. Growing up with that curiosity and the beauty of the natural surroundings motivated me to start this company.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What are the advantages and challenges of building a mission driven company?</b></p>
                        <p>I think it comes with mostly advantages. Having a strong mission attracts really passionate people. We have an engineer that quit Google after seven years because she strongly believed in the mission. Not every startup can attract highly talented people this way. On the other hand, it also sets a really high bar because we cannot hire anybody. We can only hire mission-driven people, and have rejected candidates that are strong on paper but do not seem mission aligned.</p>
                    </div>
                    <div className="interview-question">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p><b className="interview-question-q">What companies do you admire?</b></p>
                                <p>Sometimes I say that I want us to be the Patagonia of tech. I admire the way that the founder, Yvon Chouinard, built the company values and first principles. Tesla is another great example of a climate tech company that started with the mission of electrifying cars and also built incredible products. I also admire the idealism and storytelling of Disney. We have not even scratched the surface of storytelling on our platform. With 45 incredible projects full of life, both the wildlife and the people protecting the forests, we are going to work on videos to share their stories.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/pachama_image4.jpeg" className="blogpost-img"/>
                                <p className="img-caption">Some of the Pachama team climbing Mount Shasta and connecting over their love for Mother Earth.</p>
                            </Col>
                        </Row>
                    </div>
                    <h4 className="blog-quote">“Having a strong mission attracts really passionate people.”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is all the recent hype around carbon offsetting API?</b></p>
                        <p>It comes down to consumers demanding carbon neutral services. We built an API to make it easier for companies to offer carbon neutral products by automatically purchasing credits. This reduces their need for an entire team doing the accounting and offset purchasing. It can easily be incorporated as microtransactions on purchases for transportation, travel or retail.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people get involved and support your effort?</b></p>
                        <p>We are hiring, so check out our . We are always looking to connect with companies that are interested in taking climate actions, so connect us to your company and share the word about what we are doing.</p>
                        <p>We are also planning to expand our consumer product, so people can offset their personal emissions. Right now, anybody can go to our <a className="blog-link" href="https://pachama.com/myimpact" target="_blank" rel="noopener noreferrer">website and financially support a project.</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import edlyft from '../tmpImages/edlyft.png';
import ei from '../tmpImages/ei.png';
import dispatch from '../tmpImages/dispatch.png';
import pachama from '../tmpImages/pachama.png';
import modernhealth from '../tmpImages/modernhealth.png';
import corsali from '../tmpImages/corsali.png';
import top_10_mental_health from '../tmpImages/top_10_mental_health.png';
import mental_health_market_report from '../tmpImages/mental_health_trend_header.png';
import welbi from '../tmpImages/welbi.png';
import vv_1 from '../tmpImages/vv_1.png';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import green_banner from '../tmpImages/7.png';
import { Helmet } from "react-helmet";
import { useState } from 'react';
import { sendEmail } from '../components/SendEmail'
import Image from 'react-bootstrap/Image';
import ppp from '../tmpImages/3Ps Venn Diagram.png';
import { BlogCard } from '../components/BlogCard'
import teaching_to_tech_banner from '../tmpImages/teaching_to_tech.png'


export function Home({navColor}) {
    navColor("green-nav")
    const [email, setEmail] = useState('')

    const handleEmailChange = event => {
        setEmail(event.target.value)
    };

    const handleSubmit = event => {
        event.preventDefault()
        sendEmail(email, "newsletter-email", 'sign-up-button')
    };
    return <div>
        <Helmet>
            <title>Virtuous Ventures - Home</title>
        </Helmet>
        <div className="section-1">
            <div className="waitlist-signup-input">
                <h3 className="find-job-header">
                    Sign up for our newsletter
                </h3>
                <h5 className="find-job-subheader ">
                    Get all the latest content and feature updates delivered to your email.
                </h5>
                <form onSubmit={handleSubmit}>
                <InputGroup size="lg" className="mb-3 signup-group">
                    <FormControl
                    aria-label="Recipient's email"
                    placeholder="Enter your email.."
                    className = "signup-input"
                    id="newsletter-email" name="newsletter-email"
                    onChange={handleEmailChange}
                    value={email}
                    type="email" required
                    />
                    <div className="signup-btn-div" id="signup-btn-div">
                        <Button variant="outline-secondary" id="sign-up-button" className="sign-up-button category-button" type="submit">Sign Up</Button>
                    </div>
                </InputGroup>
                </form>
            </div>
        </div>
        <div className="section-2">
            <Container fluid className="banner-row">
                <Row>
                    <Col className="value-prop-column"> 
                        <h2>
                            Working with changemakers
                        </h2>
                        <h5 className="home-top-text">
                            Virtuous Ventures works with early stage companies that develop products for positive impact.  It explores how they leverage technology for good and the unique challenges at the intersection of <b>people</b>, <b>planet</b> and <b>profit</b>.
                        </h5>
                        <p>→ <a className="generic-link" href="/about">Learn more</a></p>
                    </Col>
                    <Col>
                        <Image class="img-fluid" className="ppp-img align-center" src={ppp}/>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="section-3">
            <h4 className="blogpost-title">Founder stories and more</h4>
            <p className="blogpost-proposition">We strive to generate content that provides value to mission-driven founders, value-oriented jobseekers, and impact-influenced investors.</p>
            <div className="blogpost-div">
            <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={teaching_to_tech_banner}
                                title="From Teaching to Tech"
                                description="How your experience as an educator can transfer into the EdTech industry."
                                link="/blog/teaching-to-tech" />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={welbi}
                                title="Welbi"
                                description="Data-visualization and recommendations to tackle the social isolation epidemic."
                                link="/blog/welbi" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={top_10_mental_health}
                                title="Top 10 Startups Innovating in Mental Health"
                                description="Our favorite startups in mental health based on their innovative approaches, rapid growth and traction."
                                link="/blog/top-10-mental-health" />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={mental_health_market_report}
                                title="Startup Market Trends in Mental Health"
                                description="Market trends report on the mental health industry."
                                link="/blog/mental-health-trends" />
                        </Col>
                        </Row>
                        <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={corsali}
                                title="Corsali"
                                description="Creating economic opportunity through labeling data anywhere on low-bandwidth mobile devices."
                                link="/blog/corsali" />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={green_banner}
                                title="Virtuous Ventures Launching Company Search Feature"
                                description="Find companies that align with your values."
                                link="/blog/company-search-announce" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={pachama}
                                title="Pachama"
                                description="Applying Artificial Intelligence to measure forest carbon sequestration."
                                link="/blog/pachama" />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}  className="blogcard-col">
                            <BlogCard
                                imageSrc={modernhealth}
                                title="Modern Health"
                                description="Democratizing access to mental health resources by distributing through employers."
                                link="/blog/modernhealth" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}  className="blogcard-col">
                            <BlogCard
                                imageSrc={edlyft}
                                title="Edlyft"
                                description="Equipping more students with the tools to crush their STEM coursework and land top jobs in tech."
                                // link="/blog/edlyft" />
                                link="/blog/edlyft"/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={ei}
                                title="Emerging Impact"
                                description="Providing the technical expertise for non-profits to address the most pressing needs of society and our planet."
                                link="/blog/emergingimpact"/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={dispatch}
                                title="Dispatch Goods"
                                description="How Dispatch Goods is changing the way we consume as a society."
                                link="/blog/dispatch-goods" />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6} className="blogcard-col">
                            <BlogCard
                                imageSrc={vv_1}
                                title="The Beginning of Virtuous Ventures"
                                description="“If you stand for nothing Burr, what will you fall for?”"
                                link="/blog/beginning-of-vv" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </div>;
}
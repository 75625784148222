import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import modernhealth from '../../tmpImages/modernhealth.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"

export function ModernHealth() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Modern Health</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Modern Health</h2>
                        <h4 className="blogpost-subheader">Democratizing access to mental health resources by distributing through employers.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Feb 9, 2021 · 10 min read</p>
                            </div>
                        </div>      
                    </div>
                    <Image variant="top" src={ modernhealth } className="blogpost-header-img"/>

                    <p><em>With over a year of a pandemic that has lead to social separation, an awakening to cultural and systemic social injustices, an ongoing environmental crisis, and the stress of the modern working lifestyles, individuals are more interested than ever to engage in their mental health. But the question becomes “how?” No one has cracked the code on the way to solve mental health at scale in the most cost-effective way. I chatted with Alyson Watson, Founder and CEO at Modern Health about how they are attempting to achieve just that.</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What inspired you to start a company in the mental health space?</b></p>
                        <p>Growing up, I struggled with anxiety. My first time seeing a therapist, I used to “sneak” out of high school to go see a shrink. I later played lacrosse at Johns Hopkins. Arguably one of the top medical environments in the world, the school had such a huge focus on our physical health as athletes, but it lacked any focus on mental health. At least half the girls on my team struggled with eating disorders, depression, or anxiety. I could not believe that no one was talking about it. From there, I became fascinated by the mental health space. My mom, a doctor in functional and holistic medicine, inspired me to become a yoga instructor and get into mindfulness and meditation. I noticed it all made a positive impact on me.</p>
                        <p>I had always wanted to start a company, and I was told that I had to move to Silicon Valley. As someone who grew up on the east coast, I did not know what “venture capital” meant. I booked a one-way ticket to move out there, and it was a complete disaster. Within two weeks of moving to San Francisco, I lost my job, my boyfriend broke up with me, and my apartment flooded. That was a moment where I felt really depressed. I called nearly 30 therapists, but no one was accepting new patients, no one felt like the right fit, and I could not afford any of them.</p>
                        <p>I finally ended up connecting to a therapist. Going through that, I not only learned how difficult it is to find a therapist, but I saw how much value I got from working with one beyond that critical moment. Once I got my feet under me, I got so much ongoing value from career development to working through relationship complications. It made me question why we wait until we are in crisis mode to get help. We should be engaging in our mental health from a preventive perspective. That was the lightbulb moment for me.</p>
                    </div>
                    <h4 className="blog-quote">“We should be engaging in our mental health from a preventive perspective.”</h4>
                    <div className="interview-question">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/modernhealth_image1.png" className="blogpost-img"/>
                                <p className="img-caption">The early days of Modern Health.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p><b className="interview-question-q">How did that lead to Modern Health?</b></p>
                                <p>I ended up working at a startup called Collective Health where I had a front row seat to the problem. Collective Health was administering health plans for employers, and would look for other digital health companies as partners. I got to look at the mental health space offerings, and I saw all these point solutions but they were not engaging a broad population. They were super confusing, and it was unclear to users where to go for what. For example if you wanted to engage in mindfulness, you had to use a meditation app, but if you needed therapy, you had to go somewhere else.</p>
                            </Col>
                        </Row>
                        <p>That is when I realized there should be a solution out there that helps people figure out where to go based on their needs and triages them to the right level of care. So I left Collective Health to start Modern Health about three years ago to focus on this.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the company mission?</b></p>
                        <Row>
                            <Col xs={12} sm={12} md={8} lg={8}>
                                <p>We are a mental health benefits platform for employers. We work with employers to make it easy for their employees to get the mental health support that they need. Beyond connecting people to therapy, our mission is to get everyone to engage in their mental health. We think about mental health on this spectrum from green to red, and everyone falls somewhere on that spectrum. Let us not wait until we are in the red zone. The idea is to have employers help their employees engage proactively.</p>
                                <p>We have built a platform that enables employees to get access to the benefits completely free. They take a quick scientifically-validated assessment that helps them understand where their mental health is and tailor the experience to their goals; whether it is to improve relationships, deal with financial stress, or become more mindful. The experience can include mindfulness meditation, mental health coaching, group led session, and one-on-one therapy.</p>
                                <div className="interview-question">
                                    <p><b className="interview-question-q">How did your personal experience in finding that right therapist influence the product?</b></p>
                                    <p>One important aspect is connecting to someone that shares your values. I also think it is important for them to be a good listener and help you to become your own therapist. At the end of the day, no one is going to solve your problems for you. It is about having a safe space to talk through things that are going on in your life and help yourself come to your own conclusions.</p>
                                </div>
                            </Col>
                            <Col xs={8} sm={8} md={4} lg={4} className="center-img-div">
                                <Image src = "https://storage.googleapis.com/vv-images/modernhealth_image2.png" className="blogpost-img"/>
                                <p className="img-caption">Assessments are used to identify the best level of care and resources for employees.</p>
                            </Col>
                        </Row>
                        <p>It has been a priority for us to have a super diverse network so that everyone can feel like there is someone they can connect with. We have built a global network of therapists and coaches in over 35 countries speaking 30-plus languages.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What was the reason for focusing as a B2B product?</b></p>
                        <p>We believe that mental health has become the fourth pillar of benefits with employers. Employers did not always offer medical, dental, and vision benefits. Those became the norm, and now most employees expect that. Mental health ultimately impacts all other facets of your health including your physical health, but you cannot always get support for it through traditional health benefits. There is a global shortage of therapists, and most therapists do not accept insurance because the reimbursement rates are so low.</p>
                        <p>As mental health has become more destigmatized, employees are asking their companies for the support. There is also research that shows that investing in your employees mental health impacts a company’s bottom line. It is a huge differentiator in making the business operate successfully by setting up the people to perform their best. We view this as democratizing access to mental health resources by distributing through employers. I think there is a world in which we will eventually go direct-to-consumer, but we think that the fastest way to do it where people do not need to worry about the cost is having employers to pay for it.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What challenges do you see that are unique to the mental health space?</b></p>
                        <p>There is still a stigma associated with mental health. Part of the challenge with mental health is that we cannot cut open our brains while we are alive and look inside, so all this stuff is self-reported. We as humans are not great at self-reporting, so you are limited by what you can learn and how fast.</p>
                        <p>I think that there is a lot of area that is ripe for innovation from a technological perspective in helping people figure out how they are feeling. There is also the flip side, where not everyone wants to be using technology all day to track their mental health. How can you get people to thrive outside of technology without creating a further addiction to it? The way we work with technology in our lives today is part of the problem, so how do we use technology to be a solution?</p>
                    </div>
                    <h4 className="blog-quote">“The way we work with technology in our lives today is part of the problem, so how do we use technology to be a solution?”</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How have you scaled the business while still providing quality to end users?</b></p>
                        <p>We believe the key to scaling is to take a scientific approach and do research, but also encourage people to think outside of the box. An example is these group sessions which a few years ago would have been viewed as a voodoo way to sell mental health, but it is working. No one has cracked the code on the way to solve mental health at scale in the most cost-effective way. That is what we are trying to do.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How have you struck that balance between being a profitable company and delivering on your mission?</b></p>
                        <p>At the end of the day we do not want to be a company that always has to rely on venture capital to operate because we want to be able to build a world-class mental health solution at scale with an amazing user experience, great for the employer, and a great business. It actually works out that all the incentives are aligned. We share all of our financials with our employees to get them bought in. It is important to be transparent about the business and how it is working to foster a culture of innovation and creativity rather than shielding employees from the details. We want to be able to build a solution with a sustainable business model that also sells mental health.</p>
                        <p>We have learned firsthand that investing in your employees and their mental health will translate to success much faster. We encourage people to take the time that they need to reset. If they are feeling burned out, we encourage them to be vocal about it. Constantly having a dialogue helps. That means being open about that balance of being a startup and mental health since there is no way to be successful as a hyper-growth company without hard work.</p>
                    </div>
                    <Row className="blogpost-img-row">
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/modernhealth_image3.jpeg" className="blogpost-img"/>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Image src = "https://storage.googleapis.com/vv-images/modernhealth_image4.png" className="blogpost-img"/>
                        </Col>
                        <p className="img-caption large-img-caption">An essential part to scaling the mission has been hiring individuals who are energized to find innovative approaches to solving problems. Pre- and post- Covid-19 work-from-home policies, the team finds ways to connect.</p>
                    </Row>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What have been your biggest learnings or surprises along your journey?</b></p>
                        <p>The reason someone becomes a coach or therapist is because they truly want to help people. Building technology to allow providers to scale themselves and creating a community for them has been eye-opening. A lot of coaches and therapists have private practices and work in isolation, so this community is a space for them to share learnings and best practices. It has helped us build great technology for them and our users since this community has become a core part of the feedback loop.</p>
                        <p>Another big learning is also focused on community. When people are going through stressful times in their life, people want to talk about it. People want to lean into healing whether it is for racial injustice, the political environment or this [Covid-19] pandemic. There is this epidemic of loneliness, and people want work through these things together. We have discovered that community is going to be a big way that people engage with their mental health.</p>
                        <h4 className="blog-quote">“People want to lean into healing.”</h4>
                        <p>As an employer, we have learned that we cannot ask employees to compartmentalize work and life. If something in life is truly impacting someone’s mental health, it will impact how they show up to work, so you might as well be there for them. In response to the attack on the capital, we immediately brought the company together, Dr. Myra Altman did a company-wide group mediation session, I sent a note out to the company acknowledging that it was a hard time and we are here for all of you and stand up against those who are representing racial injustice. I received many responses saying “thanks for acknowledging this, most leaders of other companies do not” which blows my mind.</p>
                        <p>We are going to have to breed a new type of culture and leadership where we acknowledge what happens outside of work. We believe that will be part of the mental health solution for what will make companies successful. Rather than just providing access to an app for employee care, the solution is also about how the leadership team and company build a culture that is supportive.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What companies inspire you?</b></p>
                        <p>I am definitely inspired by successful female entrepreneurs who have defied all odds and built multibillion-dollar companies such as Katrina Lake at Stitch Fix and Emily Weiss at Glossier. One thing I admire about these successful female founders, they did not try to pretend to be someone they are not. They just leaned into their strength. Sara Blakely started Spanx with no relevant background, but she was like “I am good at selling, and I am going to figure this out.”</p>
                        <p>I feel like in today’s world, women so often feel like they have to be a certain way to be successful. There is a pressure that if you are a woman executive that you need to be serious and direct. When I started Modern Health, I was so insecure about the fact that I was not an engineer. But you have to bet on yourself and recognize the areas that align with your skill set and hire amazing people for the other areas. It is way more inspiring to be around leaders who inspire other people to reach their potential.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people who are reading this get involved and support your effort?</b></p>
                        <p>If they work at an employer where there is not currently a mental health solution and they think there should be, they should encourage their HR leader to invest in mental health for their employees. There needs to be a movement where everyone is vocal about it. If it is not for yourself, there is someone else within your workforce that could use the help. If you are interested in bringing our solution to your workforce, you can email info@modernhealth.com.</p>
                        <p>We also have <a className="blog-link" href="https://circles.modernhealth.com/" target="_blank" rel="noopener noreferrer">community-led sessions</a> that are free now to the public. I would encourage readers to check them out and spread the word so many people can get access to those, especially if they do not have access to any other support now.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import Image from 'react-bootstrap/Image';
import { Helmet } from "react-helmet";
import teaching_to_tech_banner from '../../tmpImages/teaching_to_tech.png'
import educator_to_edtech from '../../tmpImages/educator_to_edtech.png'
import polly_photo from "../../tmpImages/polly_photo.jpg"


export function TeachingToTech() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - From Teaching to Tech</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">From Teaching to Tech</h2>
                        <h4 className="blogpost-subheader">How your experience as an educator can transfer into the EdTech industry.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ polly_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Polly Goss</p>
                                <p className="blogpost-date-author-item">March X 2022 · X min read</p>
                            </div>
                        </div>
                    </div>
                    <Image variant="top" src={ teaching_to_tech_banner } className="blogpost-header-img"/>
                    <div className="interview-question">
                        <p>Teachers are joining <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.nbcnews.com/meet-the-press/news/great-resignation-hits-schools-across-all-positions-n1286565">The Great Resignation</a>. As many as 1 in 3 teachers in the <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.theguardian.com/uk-news/2021/apr/08/one-in-three-uk-teachers-plan-to-quit-says-national-education-union-survey">UK</a> and <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.forbes.com/sites/nickmorrison/2021/12/24/stopping-the-great-teacher-resignation-will-be-educations-big-challenge-for-2022/?sh=2fcf86aa157c">US</a> are considering leaving the profession. With the global EdTech industry <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.grandviewresearch.com/industry-analysis/education-technology-market?utm_source=prnewswire&utm_medium=referral&utm_campaign=ict_20-july-20&utm_term=education-technology-market&utm_content=rd1">booming</a>, many educators will look to EdTech companies for their next role.</p>
                        <p>Before I joined an EdTech startup in Boston, I was a secondary school teacher in London. I have spoken with many teachers on both sides of the Atlantic who are unsure how to translate their classroom experience into the Tech industry. This article looks at how teaching prepares you for non-technical roles within EdTech companies (i.e. you don't need to know how to code to do these jobs).</p>
                    </div>
                    <div className="interview-question">
                        <p><b>Learning Design and Curriculum Development</b></p>
                        <p>Learning Design and Curriculum Development roles might seem like the obvious choice for many educators looking to work in EdTech.</p>
                        <p><a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jennifer-kious/">Jennifer Kious</a>, Head of Curriculum Design & Training at <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.mentorcollective.org/">Mentor Collective</a>, a Boston based company working with higher education institutions across North America, is responsible for the company's mentor training program. In this role, Jennifer is responsible for ensuring thousands of volunteer mentors are equipped with the training and resources needed to mentor college students to help them graduate and find meaningful employment. For Jennifer, teaching gave her a "deep understanding of how to support people from all backgrounds and experiences," which "as well as a goal-oriented drive to build strong communities with a shared goal, was critical to jumping into a role in educational technology." Her advice to other educators looking to make a move into EdTech is to "find a company that has the same mission as your classroom mindset."</p>
                        <p>For teachers interested in roles in learning design and curriculum development, platforms like <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.teacherspayteachers.com/">TeachersPayTeachers</a> and <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.tes.com/">TES</a> facilitate teachers selling their learning materials online, which can be a great way to <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.edsurge.com/research/guides/so-you-want-to-work-in-edtech#Work-in-Edtech-Without-Leaving-the-Classroom">gain EdTech experience without leaving the classroom</a>.</p>
                    </div>
                    <div className="interview-question">
                        <p><b>Customer Success</b></p>
                        <p><a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://blog.hubspot.com/service/customer-success">Customer Success</a> teams work with clients to ensure they get the most value from using a company's product or services. In EdTech companies, the customer (who may or may not also be the primary product user) will typically be an administrator, teacher, parent or student.</p>
                        <p><a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/katie-ginsburg-56913a48/">Katie Ginsburg</a> is a former English language teacher. She now works as a Senior Customer Success Manager at <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://clever.com/">Clever Inc</a>, a digital learning platform that works with schools across the US. Empathy, listening, communication, and adaptability are the traits and skills that Katie thinks are essential to success as a customer success manager - skills that many teachers have honed from years in the classroom.</p>
                        <p>"Customer Success Managers (CSMs) are the bridge between external and internal stakeholders at a company. They are providing the marketing and product teams with the customer insights that they need, as well as providing clients with updates and information from these internal teams," Katie said. She continued, "empathy is the most important skill that a CSM can have. In order to make your product or service vital to your customer, you need to understand what drives them, what frustrates them, and what excites them. Your customer will give you all of that information if you have the empathy to be on their team and meet them where they are.</p>
                    </div>
                    <div className="interview-question">
                        <p><b>Sales and Marketing</b></p>
                        <p>Hiring former teachers to promote and sell EdTech products can be advantageous for companies, particularly startups, looking to build brand credibility in the sector. I started my career in EdTech in a sales role and quickly found that effective selling and good teaching are very similar. Effective selling hinges on the ability to turn a no into a yes and engage an otherwise disengaged audience, a challenge most teachers face daily!</p>
                        <p>Teaching gives you first-hand knowledge of the challenges that schools and students face. This user insight is valuable to almost any role within the right EdTech company; from a marketing perspective, it allows you to craft compelling content that will resonate with your target audience.</p>
                    </div>
                    <div className="interview-question">   
                        <p><b>Founder</b></p>
                        <p><a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/oliver-rutherford-a5920981/">Oliver Rutherford</a>, CEO and Co-Founder of UniRise, an EdTech platform focused on helping students get into their dream university, said his experience teaching inspired him to set up the company. "My role as a teacher exposed me to the inequalities that inspired me to start UniRise. Had I not taught in the school I did, I wouldn't have been made so patently aware of the structural inequalities that exist for students from lower-income communities."</p>
                        <p>Creativity, resilience, communication, leadership, empathy, these core skills that allow teachers to thrive in various roles within EdTech companies also equip them to be successful entrepreneurs. And most importantly, as Oliver highlights, many teachers are likely to create companies whose products benefit the schools and students with the highest need because of their experiences in the classroom.</p>
                        <Image src = { educator_to_edtech } className="blogpost-img"/>
                        <p className="img-caption">The skills that one refines as an educator can prepare them to excel as various roles in the EdTech industry.</p>
                    </div>
                    <div className="interview-question">
                        <h3><b>Next Step: Build your Network</b></h3>
                        <p>Reading job descriptions, talking to a career coach, and networking can help you understand which role is right for you. Updating your LinkedIn and reaching out to people working in companies you are interested in can be a great way to build your network and <a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://medium.com/swlh/getting-a-job-in-ed-tech-5eb3c2c701b6">help you find your next role in EdTech</a>. However, networking is not something many teachers have had to think about before, and it can feel intimidating or off-putting.</p>
                        <p><a className="blog-link" target="_blank" rel="noopener noreferrer"  href="https://www.linkedin.com/in/max-schrijnen/">Max Schrijnen</a>, Digital Transformation Consultant at Deloitte and former Maths teacher, shared, "I didn't have LinkedIn when I was a teacher because I couldn't see the value of networking. I had to get good observations and progress through different additional responsibilities to do well. But there is no good reason for not using LinkedIn to grow your network and learn more about what's out there while you're a teacher. Doing so will enable you to move and, more importantly, enable you to understand whether you should move."</p>
                    </div>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><em><a className="blog-link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/polly-goss/">Polly Goss</a> is a former English teacher who has held various roles within EdTech startups. She is passionate about helping mission-driven EdTech companies grow their impact.</em></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

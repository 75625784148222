import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import edlyft from '../../tmpImages/edlyft.png';
import { Helmet } from "react-helmet";
import amy_photo from "../../tmpImages/amy_photo.jpeg"


export function Edlyft() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Blog - Edlyft</title>
            </Helmet>
            <div>
                <div className="blogpost-container">
                    <div className="blogpost-header-container">
                        <h2 className="blogpost-header">Edlyft</h2>
                        <h4 className="blogpost-subheader">Equipping more students with the tools to crush their STEM coursework and land top jobs in tech.</h4>
                        <div className="author-header">
                            <div>
                                <Image className="author-avatar" src={ amy_photo }/>
                            </div>
                            <div className="blogpost-date-author">
                                <p className="blogpost-date-author-item">Amy Slawson</p>
                                <p className="blogpost-date-author-item">Nov 13, 2020 · 12 min read</p>
                            </div>
                        </div>
                    </div>
                    {/* <Row>
                        <Col xs={12} sm={12} md={8} lg={8} className="blogpost-image-col">
                            <Image variant="top" src={ edlyft } className="blogpost-img"/>
                        </Col>
                    </Row> */}
                    <Image variant="top" src={ edlyft } className="blogpost-header-img"/>

                    <p><em>Recently announced 2 of the youngest black women to raise more than $1M in venture capital by <a className="blog-link" href="https://www.businessinsider.com/youngest-black-women-cofounders-raise-million-funding-venture-capital-investors-2020-10" target="_blank" rel="noopener noreferrer">Business Insider</a>, Erika Hairston and Arnelle Ansong, cofounded Edlyft with aspirations of creating the next wave of new engineers. From personal experience as computer science majors, they recognized the opportunity unlocked with the high-demand skill set but were also acutely aware of the lack of diverse graduates entering the field. I chatted with Erika about how Edlyft packages the elements that led to their success, equipping more students to crush their STEM coursework and land top jobs in tech.</em></p>
                    <p><em>This article has been edited and condensed for clarity.</em></p>
                    <p className="breakpoint-dots">• • •</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Can you give the background story and what inspired you to start Edlyft?</b></p>
                        <p>My cofounder and I actually went to high school together in Connecticut, and back then we never imagined ourselves as engineers or even knew about Silicon Valley. It was kind of fate that we both ended up pursuing CS in college. In college, I saw a documentary about how few women and minorities were in tech, but they also showed Facebook's campus and how one line of code could literally touch millions of people. I just remember thinking to myself "how could there be so few people who look like me in this field that is clearly of the future? I have to try it." That is how I ended up wanting to be a CS major with no previous context.</p>
                        <h4 className="blog-quote">"How could there be so few people who look like me in this field that is clearly of the future?"</h4>
                        <p>In our experience, computer science was probably one of the most challenging things we had ever attempted. I almost dropped out of the major over 10 times. Arnelle actually did really well in her first class but was still intimidated to take the next CS class in the sequence because of the culture around the major. It was really the combination of three things that kept us both in it:</p>
                        <ul>1. Inclusive mentors who were relatable and created a space where there were no bad questions</ul>
                        <ul>2. Ample academic support where there was no shame in feeling behind</ul>
                        <ul>3. Peers who were encouraging and supportive</ul>
                        <p>The combination of those three things are what made the difference between me trying CS once and becoming an engineer. When I ended up getting my first internship at Facebook, I just remember thinking "I feel so lucky that I stuck with this field. Way more people should have access to the kind of economic opportunity that these subjects bring."</p>
                        <p>That is the story behind Edlfyt. We wanted to package together those three things that kept us in, so more people who are intimidated or don't think they are a "CS person" will have the tools and environment they need.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What is the mission of Edlyft?</b></p>
                        <p>We want to help create the next wave of new engineers. We make learning these in-demand skills accessible, fun and social. Anyone, regardless of background, should have that access to the in-demand careers they love. We should not be the exception.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What level of education are you targeting and why?</b></p>
                        <p>Our target market are college students and adult learners. We have an emphasis on higher education and for computer science, math, and data science courses. We specifically started at public universities such as UC Berkeley and UCLA because we wanted to target places that had large, diverse student bodies and high transfer rates from community colleges. We know that STEM jobs are often the ones that have a higher return on investment on your degree, and public universities tend to be more accessible and cheaper than private universities.</p>
                    </div>
                    <div className="interview-question">
                        <p><b>How does the program work?</b></p>
                        <p>We are direct-to-consumer, so we work directly with the students versus the school - a very intentional decision. From our own experiences, we know that we want to cater to the students' needs, and sometimes what the school needs is not exactly what a student needs. In working with these students, everyone who joins Edlyft is matched with a mentor from their school who has aced the class before. That mentor, who has been trained on inclusive teaching, hosts up to three small group tutoring sessions a week, specifically catered to the students' needs and course. Students also have access to our web portal, where all sessions are recorded and uploaded, they can ask questions in our community QA, and they can access personalized study tools.</p>
                    </div>
                    <Image src = "https://storage.googleapis.com/vv-images/edlyft_image1.png" className="blogpost-img"/>
                    <p className="img-caption">Example mentoring session.</p>
                    <div className="interview-question">
                        <p><b className="interview-question-q">At what point do the students' and a school's needs conflict?</b></p>
                        <p>First, the expectations between the outcomes and what students want are really misaligned. Forty-three percent of undergrads are actually graduating underemployed from college now, meaning they are in jobs that don't require a college degree or making significantly less [than they should]. Also shocking is that 50% of STEM majors are either dropping out of their majors or out of school completely.</p>
                        <p>Our biggest incentive is helping students get in-demand careers. So we focus on those in-demand careers to address the misalignment between outcomes and the top reason students go to college - to secure financial stability.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Why did you choose to go in this route rather than coding boot camps?</b></p>
                        <p>We initially came upon the idea of Edlyft by looking at the existing models of coding boot camps, and it surprised us that 60–70% of bootcamp students actually already have university degrees, the majority of which are non-STEM subjects. These are people who are spending hundreds of thousands of dollars on a university for four years, and as bootcamps put it, "graduate with the wrong degree." Then [they] need to spend another 10 to 20 thousand dollars to go to a bootcamp. We realized we could take what bootcamps get right, such as using technology and having ample mentors, but apply it when people are already in an environment for learning.</p>
                    </div>
                    <h4 className="blog-quote">"Way more people should have access to the kind of economic opportunity that these subjects bring."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How do the schools respond to Edlyft as an additional resource for their students?</b></p>
                        <p>We always emphasize to all students who join that we do not want to be a replacement for college resources. In fact, we will first recommend free resources on campus. Oftentimes those things are not enough, which is why we will be an additional layer. In an ideal world, we should not need to exist. Edlyft is successful when students no longer need us. But this is the best way to create an inclusive environment given the current situation.</p>
                        <p>What is really cool about Edlyft is that there are not many tech companies that are led by two women of color. Even though we are open to students from all backgrounds, one thing we have been adamant about is having mentors who are representative of a student body. So 52% of our mentors are women, and what we have seen is that 50 percent of our students are women. If you know anything about computer science, only twenty percent of graduates are women. In relationship with schools, this is a great way for them to diversify and grow their STEM student body.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">So speaking of success, how do you measure success?</b></p>
                        <p>There are three key metrics that we track based on our own experience and from talking to many students in the development phase. One is confidence. If our ideal goal is to get more students in these in-demand careers, that means they need to learn the skills, and to do that they need to have the confidence to continue on with them. We will measure how confident a student feels to take the next course in their class, or how confident a session made them feel. Another metric is academic outcome - how is a student doing in their classes and on their tests. The last one is perceived helpfulness - how helpful was Edlyft to a student in understanding the concepts. Sometimes grades might not reflect how well one understands the skills, and you can still land the jobs in tech if you understand things. You don't need to get an A to do that.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Can you touch on the business model, and how you strike that balance between students who may not have a ton of money while also building a lasting company?</b></p>
                        <p>It is really important to us that Edlyft is accessible but is also a growing, scalable business. We designed our model as a monthly subscription, with a sliding scale based on a student's Expected Family Contribution (EFC). An EFC is used to determine how much to pay towards your college tuition, and so a student with a zero EFC for example would have a cheaper price for Edlyft than a student who is on full tuition for their school. That is how we strike the balance between making Edlyft accessible and students being able to afford it.</p>
                        <p>Additionally, last summer we realized there's a lot of people in tech who resonate with this struggle and do not have a child to put through Edlyft but want to buy a scholarship for a student. We have since launched a <a className="blog-link" href="https://edlyft.com/scholarships" target="_blank" rel="noopener noreferrer">scholarships feature</a>. A student can apply for a need-based scholarship and do Edlyft completely free of charge.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What challenges have you seen that are unique to the education space?</b></p>
                        <p>Before we launched, I saw several mistakes that EdTech companies would make. The first is that the average American views education as an expenditure versus an investment. It is not something that you would spend a ton of money and time on once you are out of college. What that means is a lot of EdTech companies will not be able to charge a premium for their product or what it is actually worth. We wanted to focus on higher education, where we are already filtering down the market to people who are clearly investing a ton in their education.</p>
                        <p>The second thing that is a hurdle for many EdTech companies is selling directly into schools, which oftentimes have a slow sale cycle. It makes it hard to grow at venture-backed speed. If you look at most successful EdTech companies, most of them were B2C companies. [As a direct-to-consumer business], you are able to have virality growth.</p>
                    </div>
                    <h4 className="blog-quote">"In an ideal world, we should not need to exist. Edlyft is successful when students no longer need us."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How has Edlyft and your approach as a founder shifted in response to Covid-19?</b></p>
                        <p>We actually officially launched late February. We knew that this was the problem we wanted to focus on when we quit our jobs in November, but we did not know exactly how to solve it for some time. We launched right before Covid-19, and so our initial model was actually a hybrid of an in-person and online experience where mentors could host small study groups on campus. It was helpful [when the Covid-19 pandemic started] that we were already prepared to support students a hundred percent remotely and virtually.</p>
                    </div>
                    <div className="interview-question">
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p><b> className="interview-question-q"How do you and your co-founder, Arnelle, work together?</b></p>
                                <p>I think what has really worked well is we have created a contract of what it looks like for me to show up for her so that she can do her best work and vice versa. I love working with a friend. I realized that in a traditional 9-to-5, you start to create a boundary between your work-self and your personal-self. But when you are working with someone who has known you for 10 years, the fluidity between the two is actually a breath of fresh air. I still do not see her as being a "co-worker" because it is a deeper level of trust and respect. I just admire her as a human.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/edlyft_image2.jpeg" className="blogpost-img"/>
                                <p className="img-caption">Arnelle and Erika.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">Why did it so you decided to go through an accelerator program?</b></p>
                        <p>Something that was important to Arnelle and me when we first launched was creating more structure around the process. Going from a traditional 9-to-5 job to starting a company can feel incredibly daunting, and you do not know what first step to take. We saw the accelerator program as the best way to get advice from people who have seen this many times. Also, every founder I spoke to who did <a className="blog-link" href="https://www.ycombinator.com/" target="_blank" rel="noopener noreferrer">Y Combinator (YC)</a> said the best value was getting to create a community with other founders. We wanted to opt into [this network] which helps you throughout your entire lifetime.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How did you work with YC? Do you feel they really supported you in your mission as an investor?</b></p>
                        <p>Our partners are out-of-this-world supportive. I think one thing that YC does really well is believe that at the end of the day, it is the founder's company. They are good at asking founders the right questions to help them learn faster and push them to grow quicker. Because we made a stance with our mission and how it would be core to who we are as Edlyft, they got behind that completely and held us accountable to that mission.</p>
                    </div>
                    <h4 className="blog-quote">"Having a mission-driven company just means that your "why" is crystal clear."</h4>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How has being a woman and a person of color impacted your experience as founders?</b></p>
                        <p>At a high level, fundraising is most affected by bias because in the early stage it is a hundred-percent bias. [An investor] is trying to make a judgment on whether an early stage company will be successful, which 90% of the time is wrong. They often need to make the judgement based off of the team. With fundraising, I learned we needed to be 10 times scrappier, and also that confidence will translate into who invests. People will hear what you tell them, and if you lose confidence, your potential investor will lose confidence. I think as a woman of color, it is critical to remain confident even when you feel the bias.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What was your life like growing up? How has that experience influenced why you started this company or the way you have approached it?</b></p>
                        <Row>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <Image src = "https://storage.googleapis.com/vv-images/edlyft_image3.jpeg" className="blogpost-img"/>
                                <p className="img-caption">Erika with her family as the youngest of 5 children.</p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <p>I always joke that I am the perfect mixture of my mom and my dad. My dad is a serial entrepreneur who can make a business out of anything. He is that guy who owns over 200 domain names and tries to sell them. My mom is the person who had us at protest when we were two years old, always fighting for social justice. The way I was raised was to always fight for what is right and to give voice to people who have been invisibilized. And from my dad's side, I learned if you want something, you have to hustle for it to create that change in the world.</p>
                            </Col>
                        </Row>
                        <p>I was also the youngest of five siblings. I watched so many people that I admire go through life and learn lessons. I think some of that influenced me to go into tech, such as seeing my brother as an engineer. When I got to college, knowing that I had a "math-y" family gave me a sense of confidence that I can do this.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What are some of the advantages and unique challenges that come with building a company that is mission-driven?</b></p>
                        <p>When starting a mission-driven company, you need to remember to stay core to why you started it. Especially when you are looking to get venture backing, it is really important to do as much diligence on your potential investors as those investors do on you. It is important to make sure you have aligned people on board with your mission. If you do not, then you may steer away from the goal.</p>
                        <p>In terms of its advantages, I think having a mission-driven company just means that your "why" is crystal clear. It is something that a lot of people can get behind and also relate to, which will be a benefit for growth and hiring. Also when there are challenging times, there is a very clear light at the end of the tunnel to get you through that. When Covid-19 hit, it was clear to us that our work was that much more important. Even though it was scary during those uncertain times, I still wanted to go to work and work even harder.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">What companies do you admire?</b></p>
                        <p>Definitely <a className="blog-link" href="https://fiveable.me/" target="_blank" rel="noopener noreferrer">Fiveable</a>, since their approach to EdTech and B2C model is similar. They are making fields [currently focused on AP exam preparation] accessible to more highschool students and supporting them to succeed. At the late stage scale, it may be cliche but I think LinkedIn is definitely a company that has done a great job being socially responsible and still holds its mission core.</p>
                    </div>
                    <div className="interview-question">
                        <p><b className="interview-question-q">How can people who read this get involved and support your effort?</b></p>
                        <p><a className="blog-link" href="https://edlyft.com/scholarships" target="_blank" rel="noopener noreferrer">Please purchase a scholarship for students</a>. We love to make sure that anyone can do Edlyft. Also, follow us on social media. We are constantly demystifying myths about STEM that discourage people.</p>
                        <p>Social Media Links: <a className="blog-link" href="https://www.instagram.com/edlyft/" target="_blank" rel="noopener noreferrer">Instagram</a> • <a className="blog-link" href="https://www.linkedin.com/company/edlyft/" target="_blank" rel="noopener noreferrer">LinkedIn</a> • <a className="blog-link" href="https://twitter.com/TheEdlyft" target="_blank" rel="noopener noreferrer">Twitter</a></p>
                        <p>Finally, if you know anyone who is thinking about STEM but is not sure if it is for them, please send them our way!</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

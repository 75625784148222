import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import amy_photo from "../tmpImages/amy_photo.jpeg"
import Image from 'react-bootstrap/Image'
import { Helmet } from "react-helmet";


export function About() {
    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - About</title>
            </Helmet>
            {/* <div className="about-row mt-md-3 mt-lg-3 mt-xs-0 mt-sm-0"> */}
            <div className="about-row">
                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6} className="about-col">
                        <h4 className="hey-there-header">Hey, there!</h4>
                        <h2 className="amy-intro-header">I'm Amy.</h2>
                        <p className="pl-md-3 pl-lg-3">While recently looking for my next career play, I put a strong emphasis on the company mission. Motivated by the chorus of Hamilton quotes repeating in my head, one stood out: “If you stand for nothing Burr, what will you fall for?”  Consequently, if I planned to spend 40+ hours a week in a single environment, I wanted to ensure that it was spent creating positive social and environmental impact. In my extensive search for the perfect fit, I scoured endlessly throughout my network, LinkedIn, top startup lists, venture capital profiles, ect. An internal debate evolved between the pros and cons of non-profit vs for-profit organizations. Additionally, I developed a special intrigue in the advantages and challenges for companies optimizing for people, planet, and profit (the three Ps).</p>
                        <p className="pl-md-3 pl-lg-3">I believe I found the perfect fit for me: cLabs working on Celo, a platform to enhance global financial inclusion. In the search process, however, I discovered a lot of companies doing amazing things that filled me with inspiration.  This led me to start Virtuous Ventures in hopes of passing on that inspiration.</p>
                        <p className="pl-md-3 pl-lg-3">Virtuous Ventures showcases early stage companies that develop products for positive impact. It explores how they leverage technology for good and the unique challenges at the intersection of social, environmental, and economic developments.</p>
                        <p className="pl-md-3 pl-lg-3">I can’t wait to highlight the incredible work of these startups and to hear from you.  Let me know what you think and if there’s a company that inspires you!</p>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}  className="about-col">
                        <Image class="img-fluid" className="about-img" src={amy_photo}/>
                        </Col>
                    </Row>
                </Container>
                {/* <h4 className="blogpost-title">Investments</h4>
                <div className="investments-list">
                    <p className="investment-item"><a className="opportunity-link" href="https://dispatchgoods.earth/">Dispatch Goods</a> | Partners with restaurants and consumers to provide reusable container options for takeout and delivery food.</p>
                    <p className="investment-item"><a className="opportunity-link" href="https://fiveable.me/">Fiveable</a> | An educational technology company that is committed to unlocking opportunities for young people through academic and social empowerment.</p>
                    <p className="investment-item"><a className="opportunity-link" href="https://corsali.com/">Corsali</a> | Machine learning as a service with mobile-first image annotation platform aimed at providing micro-work in developing countries.</p>
                    <p className="investment-item"><a className="opportunity-link" href="https://aetherdiamonds.com/">Aether</a> | The world's 1st carbon-negative diamonds, alchemized from air. Planet-loving and climate-positive.</p>
                    <p className="investment-item"><a className="opportunity-link" href="https://www.climatecapital.co/">Climate Capital</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://www.releaf.africa/">Releaf</a> | Meeting the market demand for palm and vegetable oils in a sustainable way in Nigeria, displacing deforestation palm.</p>
                    <p className="investment-item"><a className="opportunity-link" href="https://www.coienergyservices.com/">COI Energy</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://www.shopwearwell.com/">Wearwell</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://puregreenfranchise.com/">Puregreen</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://thebloomi.com/">Bloomi</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://vivoo.io/">Vivoo</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://sparkle.life/">Sparkle</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://pittmoss.com/">Pitmoss</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://asarasi.com/">Asarasi</a></p>
                    <p className="investment-item"><a className="opportunity-link" href="https://ceremonia.com/">Ceremonia</a> | Ceremonia is a clean hair care brand rooted in Latinx heritage. Ceremonia is on a mission to empower a confident natural that feels as good as it looks.</p>
                    <p className="investment-item"><a className="opportunity-link" href="https://carboncollective.co/">Carbon Collective</a> | Sustainable, low-fee, diversified investment portfolios built for solving climate change.</p>
                </div> */}
            </div>
        </div>
    );
}

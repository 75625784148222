import React from "react";
import { Helmet } from "react-helmet";
import groupedJobdata from '../tmpData/downloadedleveroutput.json'
import ToggleButton from 'react-bootstrap/ToggleButton';
import { useState } from 'react';
import { categoryMap } from '../components/Categories.js'
import { BsFillPlusCircleFill, BsFillXCircleFill } from "react-icons/bs";
import Button from 'react-bootstrap/Button'
import { sendEmail } from '../components/SendEmail'
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card'
import { ReactComponent as LocationSVG } from '../svg/location.svg';
import noresults from '../tmpImages/noresults.png';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';

export function Jobs() {
    let [jobEntrySize, setJobEntrySize] = useState(null)
    
    function getDecoratedJobs(filteredJobsData) {
        let numJobs = 0;
        const jobCards =  Object.keys(filteredJobsData).map((jobsCategory) => {
            return (
                <div className="job-category-item">
                    <div className="job-category-title">
                        <h2>{ jobsCategory }</h2>
                    </div>
                    { filteredJobsData[jobsCategory].map((jobItem) => {
                        numJobs++;
                        const uniqueCategories = Array.from(new Set(jobItem.categories));
                        return <Card className="job-item job-card h-100">
                            <Card.Img src={"https://storage.googleapis.com/vv_logos/"+jobItem.logo} className="job-card-image" />
                            <Card.Body>
                                <div className="job-card-body">
                                    <Card.Text><b>{jobItem.jobTitle}</b></Card.Text>
                                    <Card.Text>{jobItem.companyName}</Card.Text>
                                    <Card.Text><LocationSVG className="location-svg" /> { jobItem.jobLocation }</Card.Text>
                                    <Card.Link target="_blank" rel="noopener noreferrer" href={jobItem.jobURL}>Apply</Card.Link>
                                </div>
                                <div className="job-tags-div">
                                    {uniqueCategories.map(impactCategory => <div className="job-tag impact-category-tag impact-tag">{impactCategory}</div>)}
                                    {jobItem.subcategories.map(impactSubCategory => <div className="job-tag impact-subcategory-tag impact-tag">{impactSubCategory}</div>)}
                                </div>
                            </Card.Body>
                        </Card>
                    })
                }
                </div>
            );
        });
        setJobEntrySize(numJobs);
        return jobCards; 
    }

    const roles = Object.keys(groupedJobdata);
    roles.sort();
    const [checked, setChecked] = useState(
        new Array(roles.length).fill(false)
    );

    function updateCheckedArray(newStatus, index, checkedArray, setFunction) {
        var newCheckedArray = checkedArray.slice(0);
        newCheckedArray[index] = newStatus;
        setFunction(newCheckedArray);
    }
    
    //TODO dynamic retrieval
    const categories = Object.keys(categoryMap).filter((category) => category !== "Category");
    categories.sort();
    const subCategories = Array.from(new Set(Object.keys(categoryMap).flatMap((category) => categoryMap[category].subCategories)));
    subCategories.sort();
    
    const [categoryChecked, setCategoryChecked] = useState(
        new Array(categories.length).fill(false)
    );

    const [subCategoryChecked, setSubCategoryChecked] = useState(
        new Array(subCategories.length).fill(false)
    );

    const [email, setEmail] = useState('')
    const [showEmailPrompt, setShowEmailPrompt] = useState(true)
    const [showResults, setShowResults] = useState(false)
    const [filteredResults, setFilteredResults] = useState([])

    const handleEmailChange = event => {
        setEmail(event.target.value)
    };

    function filterJobTypeCategories(data) {
        const selectedRoles = roles.filter((role) => checked[roles.indexOf( role )])
        return Object.keys(data)
            .filter(key => selectedRoles.includes(key))
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {})
    }

    function filterCategories(data) {
        const selectedSubCategories = subCategories
            .filter((subCategory) => subCategoryChecked[subCategories.indexOf( subCategory )])
        const selectedCategories = categories
            .filter((category) => categoryChecked[categories.indexOf( category )])
        return Object.keys(data)
            .reduce((obj, jobsCategory) => {
                const filteredJobs = data[jobsCategory]
                    .filter((jobItem) => jobItem.subcategories
                        .some((subCategory) => (subCategory !== "Other" 
                        && selectedSubCategories.includes(subCategory))) 
                        || (jobItem.subcategories.includes("Other") 
                            && jobItem.categories.some((category) => selectedCategories.includes(category)))
                )
                obj[jobsCategory] = filteredJobs;
                return obj
            }, {})
    }

    function updateResults() {
        const filteredJobs = filterCategories(filterJobTypeCategories(groupedJobdata))
        setFilteredResults(getDecoratedJobs(filteredJobs))
    }

    const handleSubmit = event => {
        event.preventDefault()
        sendEmail(email, "jobseeker-email", "get-job-matches-button")
        setShowResults(true)
        updateResults()
        setShowEmailPrompt(false)
    };

    return (
        <div className="whitedrop-page">
            <Helmet>
                <title>Virtuous Ventures - Jobs</title>
            </Helmet>
            {/* TODO:  
                        3. Accordian for company details
                        5. Accordian for cards by skillse
                        5. make jobs list a daily scrap
                        5. Add level - Intern, Entry Level/Graduate, Junior (1-2 years), Mid-level (3-4 years), Senior (5-6 years), Expert & Leadership (5+ years) - can filter by years experience and mention of leveling/leadership
                        FUTURE: add filter for job level (Otta.com)
             */}
             <div className="job-matching-header">
                <h1>Find a job that aligns with your values</h1>
                <h3>The Service for Matching Talent with Purpose</h3>
            </div>
            <Accordion defaultActiveKey="0" className="jobs-match-accordian">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Skillset</Accordion.Header>
                    <Accordion.Body>
                        <div className="category-pill-group">
                            {roles.map(role => {         
                                const roleIndex = roles.indexOf( role );
                                const toggleId = "skill-toggle-" + roleIndex
                                return <span>
                                        <ToggleButton
                                        className="mb-2 role-category-pill select-pill"
                                        id={toggleId}
                                        bsPrefix="jobs-pill-checkbox"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={checked[roleIndex]}
                                        value="1"
                                        onChange={(e) => updateCheckedArray(e.currentTarget.checked, roleIndex, checked, setChecked )}
                                    >
                                    { role } {checked[roleIndex] ? <BsFillXCircleFill className="pill-icon"/> : <BsFillPlusCircleFill className="pill-icon"/> }
                                    </ToggleButton>
                                </span>})}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="0" className="jobs-match-accordian">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Value</Accordion.Header>
                    <Accordion.Body>
                        <div className="category-pill-group">
                            {categories.map(category => {         
                                const roleIndex = categories.indexOf( category );
                                const toggleId = "value-toggle-" + roleIndex
                                return <span>
                                        <ToggleButton
                                        className="mb-2 aggregate-value-category-pill select-pill"
                                        id={toggleId}
                                        bsPrefix="jobs-pill-checkbox"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={categoryChecked[roleIndex]}
                                        value="1"
                                        onChange={(e) => {
                                            updateCheckedArray(e.currentTarget.checked, roleIndex, categoryChecked, setCategoryChecked );
                                            var newCheckedArray = subCategoryChecked.slice(0);
                                            categoryMap[category].subCategories.forEach(subCategory => {
                                                const subCategoryIndex = subCategories.indexOf( subCategory );
                                                newCheckedArray[subCategoryIndex] = e.currentTarget.checked;
                                            });
                                            setSubCategoryChecked(newCheckedArray);
                                        }}
                                    >
                                    { category } {categoryChecked[roleIndex] ? <BsFillXCircleFill className="pill-icon"/> : <BsFillPlusCircleFill className="pill-icon"/> }
                                    </ToggleButton>
                                </span>})}
                            <br/>
                            {subCategories.map(subCategory => {         
                                const roleIndex = subCategories.indexOf( subCategory );
                                const toggleId = "sub-category-toggle-" + roleIndex
                                return <span>
                                        <ToggleButton
                                        className="mb-2 value-category-pill select-pill"
                                        id={toggleId}
                                        bsPrefix="jobs-pill-checkbox"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={subCategoryChecked[roleIndex]}
                                        value="1"
                                        onChange={(e) => updateCheckedArray(e.currentTarget.checked, roleIndex, subCategoryChecked, setSubCategoryChecked )}
                                    >
                                    { subCategory } {subCategoryChecked[roleIndex] ? <BsFillXCircleFill className="pill-icon"/> : <BsFillPlusCircleFill className="pill-icon"/> }
                                    </ToggleButton>
                                </span>})}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
            { showEmailPrompt ?
                <div className="job-matches-form-div">
                    <form className="job-matches-form" onSubmit={handleSubmit}>
                        {/* <form action="https://script.google.com/macros/s/AKfycbwynAVz9VAmNh5ocb5vft1MU4NkZbnM5MwLWOT8-vNsE5Zv10qndP6FScZK-9q5lNHh/exec"> */}
                        <InputGroup size="lg" className="mb-3 signup-group">
                            <FormControl
                            aria-label="Recipient's email"
                            placeholder="Enter your email.."
                            className = "signup-input"
                            id="jobseeker-email" name="jobseeker-email"
                            onChange={handleEmailChange}
                            value={email}
                            type="email" required
                            />
                            <div className="signup-btn-div" id="signup-btn-div">
                                <Button variant="outline-secondary" id="get-job-matches-button" className="sign-up-button category-button" type="submit">Get Matches</Button>
                            </div>
                        </InputGroup>
                        </form>
                    </div> : 
                    <div className="jobs-request-again-div">
                        <Button variant="outline-secondary" className="jobs-request-again-button category-button" onClick={updateResults}>Request Again</Button>
                    </div>
            }
            { showResults ? 
                <div className="job-board-div">
                    { jobEntrySize > 0 ? filteredResults : 
                        <div className="job-board-no-results-div">
                            <p>No Results. Try altering search criteria.</p>
                            <Image className="no-results-image" src={noresults}/>
                        </div>}
                </div>
            : null}

        </div>
    );
}
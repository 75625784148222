export const categoryMap = {
    "Environment": {
        "tag": "ENVIRONMENT",
        "subCategories": ["Circular Economy", "Consumerism", "Energy", "Transportation", "Carbon", "Agriculture", "Food", "Restoration and Conservation", "Sustainable Finance", "Other"]
    },
    "Social" : {
        "tag": "SOCIAL",
        "subCategories": ["Financal Inclusion", "Eldercare", "Social Justice", "Food and Water Security", "Other"]
    },
    "Health & Wellness": {
        "tag": "HEALTH",
        "subCategories": ["Mental Health", "Accessible Health Care", "Food Safety", "Other"]
    },
    "Education": {
        "tag": "EDUCATION",
        "subCategories": ["Accessible Education", "Childhood Growth and Development", "Career Skill Development", "Tutoring and Student Support", "Educator Tooling", "Affordable Education Funding", "Other"]
    },
    "Women": {
        "tag": "WOMEN",
        "subCategories": ["Equality Feminism", "Other"]
    },
    "Category": {
        "tag": "NULL",
        "subCategories": []
    },
}

//TODO get all categories and corresponding subcategories from db
export const subCategoryMap = {
    // Environment
    "Circular Economy": "ZERO_WASTE",
    "Agriculture" : "AGRICULTURE",
    "Food" : "FOOD",
    "Carbon" : "CARBON",
    "Energy" : "ENERGY",
    "Restoration and Conservation": "RESTORATION",
    "Transportation" : "TRANSPORTATION",
    "Consumerism" : "CONSUMERISM",
    "Sustainable Finance": "SUSTAINABLE_INVESTING",
    // Social
    "Financal Inclusion" : "FINANCIAL_INCLUSION",
    "Eldercare": "ELDERCARE",
    "Social Justice": "SOCIAL_JUSTICE",
    "Food and Water Security": "FODD_AND_WATER_SECURITY",
    // Women
    "Equality Feminism": "GENDER_EQUALITY",
    // Health
    "Womens Health": "WOMANS_HEALTH",
    "Mental Health": "MENTAL_HEALTH",
    "Accessible Health Care": "ACCESSIBLE_HEALTH_CARE",
    "Food Safety": "FOOD_SAFETY",
    // Education
    "Accessible Education": "ACCESSIBLE_EDUCATION",
    "Childhood Growth and Development": "CHILDHOOD_GROWTH",
    "Career Skill Development": "CAREER_SKILLS",
    "Tutoring and Student Support": "STUDENT_SUPPORT",
    "Educator Tooling": "EDUCATOR_TOOLS",
    "Affordable Education Funding": "EDUCATION_FUNDING",
    "Subcategory": "NULL"
}

export const categoryNameMap = {
    "environment": "Environment",
    "social": "Social",
    "health": "Health & Wellness",
    "education": "Education",
    "women": "Women"
}

export const subCategoryNameMap = {
    // Environment
    "zeroWaste": "Circular Economy",
    "agriculture": "Agriculture",
    "food": "Food",
    "carbon": "Carbon",
    "energy": "Energy",
    "restoration": "Restoration and Conservation",
    "transportation": "Transportation",
    "consumerism": "Consumerism",
    "sustainableInvestment": "Sustainable Finance",
    // Social
    "financialInclusion": "Financal Inclusion",
    "elderCare": "Eldercare",
    "socialJustice": "Social Justice",
    "foodAndWaterSecurity": "Food and Water Security",
    // Women
    "genderEquality": "Equality Feminism",
    // Health
    "womensHealth": "Womens Health",
    "mentalHealth": "Mental Health",
    "accessibleHealthCare": "Accessible Health Care",
    "foodSafety": "Food Safety",
    // Education
    "accessibleEducation": "Accessible Education",
    "childhoodGrowth": "Childhood Growth and Development",
    "careerSkills": "Career Skill Development",
    "studentSupport": "Tutoring and Student Support",
    "educatorTooling": "Educator Tooling",
    "educationFunding": "Affordable Education Funding",
    // All
    "other": "Other",
}